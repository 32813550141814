import _trim from "lodash/trim";

import { sortPenalties } from "@/utils/sortPenalties";
import { sortByPeriodAndTime, sortByPeriodAndTimeReverse } from "@/utils/sortByPeriodAndTime";
import normalizeJersey from "@/components/GameForm/components/Lineup/components/JerseyInput/normalizeJersey";
import { getMeta, getSeason } from "../currentSeason";
import { formatPeriodName } from "../../components/Gamesheet/components/TeamsLayout/formatPeriodName";

const selectRoot = state => state.gameForm;
const selectFields = state => selectRoot(state).fields;
const selectField = (state, name) => selectFields(state)[name];
const selectFieldValue = (state, name) => selectField(state, name).value;
const selectData = state => selectRoot(state).data;
const selectSearchPagination = state => selectRoot(state).seasonPlayerPagination;
const selectAttributes = state => selectData(state).attributes;
const selectMeta = state => selectRoot(state).meta;
const selectPenalties = (state, team) => selectRoot(state).penalties[team];
const selectGoals = (state, team) => selectRoot(state).goals[team];
const selectGoalieShifts = (state, team) => selectRoot(state).goalieShifts[team];
const selectShots = state => selectRoot(state).shots;
const selectSeasonPlayers = state => selectRoot(state).seasonPlayers;
const selectFormErrors = state => selectRoot(state).formErrors;

export const getSearchCurrentQuery = state => selectSeasonPlayers(state).currentQuery;

export const getSearchPaginationLimit = state => selectSearchPagination(state).limit;
export const getSearchPaginationOffset = state => selectSearchPagination(state).ids.length;
export const getSearchListIsAppending = state => selectSearchPagination(state).append;
export const getSearchListTotalCount = state => selectSearchPagination(state).totalCount;
export const getSearchListFilteredCount = state => selectSearchPagination(state).filteredCount;
export const getSearchListOffset = state => selectSearchPagination(state).ids.length;
export const getSearchListLimit = state => selectSearchPagination(state).limit;
export const getSearchListIsLoaded = state => selectSearchPagination(state).isLoaded;
export const getSearchListIsLoading = state => selectSearchPagination(state).isLoading;
export const getSearchListHasMore = state => {
  const filteredCount = getSearchListFilteredCount(state);
  const offset = getSearchListOffset(state);
  return offset < filteredCount;
};

const makeStartTime = state => {
  const dateValue = selectFieldValue(state, "date");
  const timeValue = selectFieldValue(state, "startTime");

  return `${dateValue}T${timeValue}:00Z`;
};

const makeEndTime = state => {
  const dateValue = selectFieldValue(state, "date");
  const timeValue = selectFieldValue(state, "endTime");

  if (timeValue === "") {
    return null;
  }

  return `${dateValue}T${timeValue}:00Z`;
};

const makeCurfew = state => {
  const { curfew } = selectAttributes(state);
  const dateValue = selectFieldValue(state, "date");
  const timeValue = selectFieldValue(state, "curfewTime");

  if (timeValue && timeValue !== "") {
    return { ...curfew, time: `${dateValue}T${timeValue}:00Z` };
  }

  return curfew;
};

const makePenalties = (state, team) => Object.values(getGameFormTeamPenalties(state, team)).sort(sortPenalties);

const makeGoals = (state, team) => Object.values(getGameFormTeamGoals(state, team)).sort(sortByPeriodAndTime);

const makeGoalieShifts = (state, team) =>
  Object.values(getGameFormTeamGoalieShifts(state, team)).sort(sortByPeriodAndTime);

const makeShots = (state, team) =>
  // We should make shots based on the shots against opposite team's goalies.
  // So if you want to get _home_ shots you have to iterate over _visitor_ goalies.
  getGameFormTeamGoalies(state, team === "home" ? "visitor" : "home")
    .map(({ id: goalieId }) => {
      const shots = selectShots(state)[goalieId] || {};

      return Object.entries(shots)
        .map(([period, count]) => {
          return Array(count).fill({ period: formatPeriodName(period), goalieId });
        })
        .flat();
    })
    .flat();

function makeShootouts(state, team) {
  return Object.values(selectRoot(state).shootouts[team]).filter(({ number, shooterId, goalieId, result }) => {
    if (typeof number !== "number" || number < 1) {
      return false;
    }

    if (_trim(shooterId) === "" || _trim(goalieId) === "" || _trim(result) === "") {
      return false;
    }

    return true;
  });
}

// duplicate of function in src/redux/gamesheet/selectors.js
const getPenaltyLabel = (state, code) => {
  const data = selectData(state);
  if ("penaltyTypes" in data && code in data.penaltyTypes) {
    return data.penaltyTypes[code];
  }

  return code;
};

export const getGameFormFields = selectFields;

export const getGameFormNextAttributes = state => {
  const currentAttributes = selectAttributes(state);
  delete currentAttributes.startTimeGmt;
  delete currentAttributes.endTimeGmt;
  delete currentAttributes.scheduledTimeGmt;

  const homeSuspensions = {
    coaches: currentAttributes.homeSuspensions.coaches,
    players: getGameTeamPlayerSuspensions(state, "home")
  };

  const visitorSuspensions = {
    coaches: currentAttributes.visitorSuspensions.coaches,
    players: getGameTeamPlayerSuspensions(state, "visitor")
  };

  return {
    ...currentAttributes,
    startTime: makeStartTime(state),
    endTime: makeEndTime(state),
    curfew: makeCurfew(state),
    location: selectFieldValue(state, "location"),
    category: selectFieldValue(state, "category"),
    number: selectFieldValue(state, "number"),
    timeZoneName: selectFieldValue(state, "timeZone"),
    timeZoneOffset: selectFieldValue(state, "offset"),
    gameType: {
      title: selectFieldValue(state, "gameType"),
      gamesPlayed: parseInt(selectFieldValue(state, "gamesPlayed")),
      gamesTotal: parseInt(selectFieldValue(state, "gamesTotal"))
    },
    homePenalties: makePenalties(state, "home"),
    visitorPenalties: makePenalties(state, "visitor"),
    homeGoals: makeGoals(state, "home"),
    visitorGoals: makeGoals(state, "visitor"),
    homeGoalieShifts: makeGoalieShifts(state, "home"),
    visitorGoalieShifts: makeGoalieShifts(state, "visitor"),
    homeShots: makeShots(state, "home"),
    visitorShots: makeShots(state, "visitor"),
    visitorShootoutAttempts: makeShootouts(state, "visitor"),
    homeShootoutAttempts: makeShootouts(state, "home"),
    homeFppEarned: getGameFormTeamFairPlayEarned(state, "home"),
    visitorFppEarned: getGameFormTeamFairPlayEarned(state, "visitor"),
    homeRoster: getGameFormTeamMembers(state, "home"),
    visitorRoster: getGameFormTeamMembers(state, "visitor"),
    homeSuspensions: homeSuspensions,
    visitorSuspensions: visitorSuspensions
  };
};

export const getGameFormDataTeams = state => {
  const { visitorId, homeId } = selectData(state);

  return { visitorId, homeId };
};

export const getGameFormGameCategories = state => selectData(state).categories;

export const getGameFormIsLoading = state => selectMeta(state).isLoading;
export const getGameFormIsLoaded = state => selectMeta(state).isLoaded;
export const getGameFormIsWorking = state => selectMeta(state).isWorking;
export const getGameFormIsDeleting = state => selectMeta(state).isDeleting;
export const getGameFormIsForbidden = state => selectMeta(state).isForbidden;
export const getGameFormError = state => selectMeta(state).error;

export const getGameFormTeamTitle = (state, teamType) => selectData(state)[`${teamType}Title`];

export const getGameFormTeamId = (state, teamType) => selectData(state)[`${teamType}Id`];

export const getGameSeasonId = state => selectData(state)["seasonId"];

export const getGameFormTeamPenalties = (state, teamType) => {
  const penalties = selectPenalties(state, teamType);

  return Object.entries(penalties).map(([id, data]) => ({ id, ...data }));
};

export const getGameFormPeriodNames = state =>
  Object.keys(selectAttributes(state).periods || {})
    .sort()
    .map(period => period.toUpperCase());

const canRemovePlayer = (state, team, playerId) => {
  let canRemove = true;

  const goals = getGameFormTeamGoals(state, team);
  for (const goal of goals) {
    if (playerId == goal.scorerId || playerId == goal.assistAId || playerId == goal.assistBId) {
      canRemove = false;
      break;
    }
  }
  if (!canRemove) return false;

  const shootOut = getGameFormTeamShootouts(state, team);
  for (const shots of shootOut) {
    if (playerId == shots.shooterId) {
      canRemove = false;
      break;
    }
  }
  if (!canRemove) return false;

  const goalies = getGameFormTeamGoalieShifts(state, team);
  for (const goalie of goalies) {
    if (playerId == goalie.goalieId) {
      canRemove = false;
      break;
    }
  }
  if (!canRemove) return false;

  const penalties = getGameFormTeamPenalties(state, team);
  for (const penalty of penalties) {
    if (
      playerId == penalty.servedById ||
      (penalty.penalized && penalty.penalized.type === "players" && playerId == penalty.penalized.id)
    ) {
      canRemove = false;
      break;
    }
  }
  if (!canRemove) return false;

  return true;
};

export const getGameFormTeamMembers = (state, team) => ({
  coaches: selectData(state)[`${team}Coaches`],
  players: selectData(state)[`${team}Lineup`]
});

export const getGameFormTeamLineup = (state, team) => {
  const data = selectData(state)[`${team}Lineup`];
  if (!data) return [];
  const freshData = data.map(a => Object.assign({}, a));
  freshData.forEach(player => {
    player.canRemove = canRemovePlayer(state, team, player.id);
  });
  return freshData.sort((a, b) => (a.lastName > b.lastName ? 1 : -1));
};

export const getTeamNormJerseysPlaying = (state, team) =>
  (selectData(state)[`${team}Lineup`] || [])
    .filter(({ status }) => status === "playing")
    .map(({ number }) => normalizeJersey(number));

export const getGameFormTeamPlayers = (state, team) =>
  selectData(state)
    [`${team}Lineup`].filter(player => player.status === "playing")
    .sort((a, b) => Number(a.number) - Number(b.number));

export const getGameFormSuspendedPlayers = state => {
  return selectData(state)["suspendedPlayers"];
};

export const getGameFormTeamCoaches = (state, team) =>
  selectData(state)[`${team}Coaches`].filter(coach => coach.status === "coaching");

export const getGameFormPenaltyLengths = state => selectData(state).penaltyLengths;

export const getGameFormPenaltyCodes = state => {
  return selectRoot(state).penaltyCodes;
};

export const getGameFormTeamGoalies = (state, team) =>
  selectData(state)
    [`${team}Lineup`].filter(({ position, status }) => position === "goalie" && status === "playing")
    .sort((a, b) => Number(a.number) - Number(b.number));

export const getGameFormTeamGoals = (state, team) => {
  const goals = selectGoals(state, team);
  const season = getSeason(state);
  const meta = getMeta(state);

  let sortFn = sortByPeriodAndTime;
  if (meta.isLoaded && season.sport === "soccer") {
    sortFn = sortByPeriodAndTimeReverse;
  }

  return Object.entries(goals).map(([id, data]) => ({ id, ...data })).sort(sortFn);
};

export const getGameFormTeamGoalieShifts = (state, team) => {
  const shifts = selectGoalieShifts(state, team);

  return Object.entries(shifts).map(([id, data]) => ({ id, ...data }));
};

export const getGameFormTeamGoaliesHadShift = (state, team) => {
  const shifts = selectGoalieShifts(state, team);
  return Object.entries(shifts).map(([_, data]) => data.goalieId);
};

export const getGameGoalieShots = state => {
  const goalies = [...getGameFormTeamGoalies(state, "visitor"), ...getGameFormTeamGoalies(state, "home")];

  const periods = getGameFormPeriodNames(state);
  const shots = selectShots(state);

  const normalizedShots = Object.keys(shots).reduce((acc, goalieId) => {
    acc[goalieId] = Object.entries(shots[goalieId]).reduce((periodAcc, [period, shotCount]) => {
      const normalizedPeriod = formatPeriodName(period);

      // Merge duplicate OT periods
      if (normalizedPeriod in periodAcc) {
        periodAcc[normalizedPeriod] += shotCount;
      } else {
        periodAcc[normalizedPeriod] = shotCount;
      }
      return periodAcc;
    }, {});
    return acc;
  }, {});

  return goalies.map(({ id, firstName, lastName, number }) => ({
    goalieId: id,
    goalieName: `${firstName} ${lastName}`,
    goalieNumber: number,
    shots: periods.map(period => ({
      period,
      shots: (normalizedShots[id] || {})[period] || 0
    }))
  }));
};

export const getGameFormFlags = state => {
  const { flags } = selectData(state);
  for (let i = 0; i < flags.length; i++) {
    if (flags[i]["code"] !== undefined) {
      flags[i]["label"] = getPenaltyLabel(state, flags[i]["code"]);
    }
  }

  return flags;
};

export const getGameFormTeamShootouts = (state, team) => {
  const { [team]: attempts } = selectRoot(state).shootouts;

  return Object.entries(attempts).reduce((result, [index, attrs]) => [...result, { ...attrs, index }], []);
};

export const getGameFormTeamTotalPenaltyMinutes = (state, team) => {
  const penalties = selectPenalties(state, team);

  return Object.values(penalties)
    .map(({ length }) => Number(length))
    .filter(length => length)
    .reduce((total, length) => total + length, 0);
};

export const getGameFormTeamFairPlayEarned = (state, teamType) => selectData(state)[`${teamType}FppEarned`];

export const getGameFormTeamDivisionTitle = (state, teamType) => selectData(state)[`${teamType}DivisionTitle`];

export const getMaxFairPlayPenaltyMinutes = state => selectData(state)[`maxFppPenaltyMinutes`];

export const getFppEnabled = state => selectData(state)[`fppEnabled`];

export const getGameSeasonPlayers = state =>
  selectSeasonPlayers(state).players.sort((a, b) => (a.lastName > b.lastName ? 1 : -1));

export const getGameTeamPlayerSuspensions = (state, team) => {
  const suspensions = selectData(state).suspendedPlayers;
  return selectData(state)
    [`${team}Lineup`].filter(p => p.status == "suspended")
    .reduce((collector, player) => {
      return [
        ...collector,
        {
          id: player.id,
          number: suspensions[player.id].number,
          length: suspensions[player.id].length
        }
      ];
    }, []);
};

export const getCanSubmitForm = state => Object.keys(selectFormErrors(state)).length === 0;

export const getFormErrorMessages = state => Object.values(selectFormErrors(state));

export const getIsPristine = state => selectMeta(state).isPristine;
