export default function buildInitialAbilityRules(roles) {
	const rules = [];

	// All roles
	if (roles.length > 0) {
		rules.push(
			...[
				{ subject: "associations", actions: ["read"] },
				{ subject: "teamLocks", actions: ["read"] },
			]
		);
	}

	// Team Managers
	if (
		roles.find(
			({ title, level: { type } }) => title === "manager" && type === "teams"
		)
	) {
		rules.push(
			...[{ subject: "email-notification-settings", actions: ["manage"] }]
		);
	}

	const rulesByTitle = buildRulesByTitleOnly(roles);
	const rulesAtGlobalLevel = buildGlobalRules(roles);
	rules.push(...rulesByTitle, ...rulesAtGlobalLevel);

	return rules;
}

function buildRulesByTitleOnly(roles) {
	const rules = [];

	// Any Admininistrator
	if (roles.find(({ title }) => title === "administrator")) {
		rules.push(
			...[
				{
					subject: "invitations",
					actions: ["create", "read", "update", "delete"],
				},
				{ subject: "users", actions: ["create", "read", "update", "delete"] },
				{ subject: "reports", actions: ["read"] },
				{
					subject: "ipadKeys",
					actions: ["create", "read", "update", "delete"],
				},
			]
		);
	}

	// Any Director or Convenor
	if (roles.find(({ title }) => ["director", "convenor"].includes(title))) {
		rules.push(
			...[
				{ subject: "invitations", actions: ["read"] },
				{ subject: "reports", actions: ["read"] },
				{ subject: "ipadKeys", actions: ["read"] },
			]
		);
	}

	// Any Observer
	if (roles.find(({ title }) => ["observer"].includes(title))) {
		rules.push(
			...[
				{ subject: "invitations", actions: ["read"] },
				{ subject: "reports", actions: ["read"] },
				{ subject: "ipadKeys", actions: ["read"] },
			]
		);
	}

	// Any Manager
	if (roles.find(({ title }) => title === "manager")) {
		rules.push(...[{ subject: "ipadKeys", actions: ["read"] }]);
	}

	return rules;
}

function hasGlobalRoleWithTitle(roles, title) {
	return !!roles.find(
		(role) =>
			role.title === title && role.level.type === "" && role.level.id === "*"
	);
}

function buildGlobalRules(roles) {
	const isAdmin = hasGlobalRoleWithTitle(roles, "administrator");
	const isDirector = hasGlobalRoleWithTitle(roles, "director");
	const isConvenor = hasGlobalRoleWithTitle(roles, "convenor");
	const isManager = hasGlobalRoleWithTitle(roles, "manager");
	const isScheduler = hasGlobalRoleWithTitle(roles, "scheduler");
	const isObserver = hasGlobalRoleWithTitle(roles, "observer");
	const isGuest = hasGlobalRoleWithTitle(roles, "guest");

	const rules = [];

	if (isAdmin) {
		rules.push(
			...[
				{ subject: "all", actions: ["create", "delete", "read", "update"] },
				{ subject: "associations", actions: ["update"] },
				{
					subject: "coaches",
					actions: ["addToTeam", "create", "delete", "merge", "read", "update"],
				},
				{
					subject: "divisions",
					actions: ["create", "delete", "read", "update"],
				},
				{ subject: "gameNotes", actions: ["create", "read"] },
				{ subject: "gameRefereeReports", actions: ["read"] },
				{ subject: "games", actions: ["delete", "read", "update"] },
				{ subject: "leagues", actions: ["create", "delete", "update"] },
				{ subject: "liveScoringMode", actions: ["update"] },
				{ subject: "penaltyReports", actions: ["read"] },
				{
					subject: "players",
					actions: ["addToTeam", "create", "delete", "merge", "read", "update"],
				},
				{ subject: "refereeReports", actions: ["read"] },
				{
					subject: "referees",
					actions: ["create", "delete", "merge", "read", "update"],
				},
				{
					subject: "roster",
					action: [
						"addCoach",
						"addPlayer",
						"createCoach",
						"createPlayer",
						"import",
						"read",
						"update",
					],
				},
				{ subject: "rosterMemberActions", actions: ["read"] },
				{ subject: "scheduledGames", actions: ["create", "delete", "update"] },
				{ subject: "brackets", actions: ["create", "delete", "update"] },
				{
					subject: "seasons",
					actions: ["create", "delete", "import", "update"],
				},
				{
					subject: "teams",
					actions: [
						"addCoach",
						"addPlayer",
						"copy",
						"create",
						"delete",
						"read",
						"update",
					],
				},
				{
					subject: "teamLocks",
					actions: ["create", "update", "delete", "ignore"],
				},
			]
		);
	}

	if (isDirector) {
		rules.push(
			...[
				{ subject: "associations", actions: ["update"] },
				{
					subject: "coaches",
					actions: ["addToTeam", "create", "delete", "merge", "read", "update"],
				},
				{
					subject: "divisions",
					actions: ["create", "delete", "read", "update"],
				},
				{ subject: "gameNotes", actions: ["create", "read"] },
				{ subject: "gameRefereeReports", actions: ["read"] },
				{ subject: "games", actions: ["delete", "read", "update"] },
				{ subject: "leagues", actions: ["update"] },
				{ subject: "penaltyReports", actions: ["read"] },
				{
					subject: "players",
					actions: ["addToTeam", "create", "delete", "merge", "read", "update"],
				},
				{ subject: "refereeReports", actions: ["read"] },
				{
					subject: "referees",
					actions: ["create", "delete", "merge", "read", "update"],
				},
				{
					subject: "roster",
					actions: [
						"addCoach",
						"addPlayer",
						"createCoach",
						"createPlayer",
						"import",
						"read",
						"update",
					],
				},
				{ subject: "rosterMemberActions", actions: ["read"] },
				{ subject: "scheduledGames", actions: ["create", "delete", "update"] },
				{ subject: "brackets", actions: ["create", "delete", "update"] },
				{ subject: "seasons", actions: ["import", "update"] },
				{
					subject: "teams",
					actions: [
						"addCoach",
						"addPlayer",
						"create",
						"delete",
						"read",
						"update",
					],
				},
				{
					subject: "teamLocks",
					actions: ["create", "update", "delete", "ignore"],
				},
			]
		);
	}

	if (isConvenor) {
		rules.push(
			...[
				{ subject: "associations", actions: ["update"] },
				{
					subject: "coaches",
					actions: ["addToTeam", "create", "delete", "merge", "read", "update"],
				},
				{ subject: "divisions", actions: ["create", "read", "update"] },
				{ subject: "gameNotes", actions: ["create", "read"] },
				{ subject: "gameRefereeReports", actions: ["read"] },
				{ subject: "games", actions: ["read"] },
				{ subject: "leagues", actions: ["update"] },
				{ subject: "penaltyReports", actions: ["read"] },
				{
					subject: "players",
					actions: ["addToTeam", "create", "delete", "merge", "read", "update"],
				},
				{ subject: "refereeReports", actions: ["read"] },
				{
					subject: "referees",
					actions: ["create", "delete", "merge", "read", "update"],
				},
				{
					subject: "roster",
					actions: [
						"addCoach",
						"addPlayer",
						"createCoach",
						"createPlayer",
						"import",
						"read",
						"update",
					],
				},
				{ subject: "rosterMemberActions", actions: ["read"] },
				{ subject: "scheduledGames", actions: ["create", "delete", "update"] },
				{ subject: "brackets", actions: ["create", "delete", "update"] },
				{ subject: "seasons", actions: ["update"] },
				{
					subject: "teams",
					actions: ["addCoach", "addPlayer", "create", "read", "update"],
				},
				{ subject: "teamLocks", actions: ["create", "update", "delete"] },
			]
		);
	}

	if (isManager) {
		rules.push(
			...[
				{
					subject: "coaches",
					actions: ["addToTeam", "create", "read", "update"],
				},
				{ subject: "divisions", actions: ["read"] },
				{ subject: "games", actions: ["read"] },
				{ subject: "player-of-the-game", actions: ["create"] },
				{
					subject: "players",
					actions: ["addToTeam", "create", "read", "update"],
				},
				{
					subject: "roster",
					actions: [
						"addCoach",
						"addPlayer",
						"createCoach",
						"createPlayer",
						"import",
						"read",
						"update",
					],
				},
				{ subject: "rosterMemberActions", actions: ["read"] },
				{ subject: "scheduledGames", actions: ["create", "update"] },
				{ subject: "brackets", actions: ["create", "update"] },
				{ subject: "teams", actions: ["read", "update"] },
			]
		);
	}

	if (isScheduler) {
		rules.push(
			...[
				{ subject: "divisions", actions: ["read"] },
				{ subject: "games", actions: ["read"] },
				{ subject: "scheduledGames", actions: ["create", "delete", "update"] },
				{ subject: "brackets", actions: ["create", "delete", "update"] },
				{ subject: "teams", actions: ["read"] },
			]
		);
	}

	if (isObserver) {
		rules.push(
			...[
				{ subject: "coaches", actions: ["read"] },
				{ subject: "divisions", actions: ["read"] },
				{ subject: "gameNotes", actions: ["create", "read"] },
				{ subject: "gameRefereeReports", actions: ["read"] },
				{ subject: "games", actions: ["read"] },
				{ subject: "penaltyReports", actions: ["read"] },
				{ subject: "players", actions: ["read"] },
				{ subject: "refereeReports", actions: ["read"] },
				{ subject: "referees", actions: ["read"] },
				{ subject: "roster", actions: ["read"] },
				{ subject: "rosterMemberActions", actions: ["read"] },
				{ subject: "teams", actions: ["read"] },
			]
		);
	}

	if (isGuest) {
		rules.push(
			...[
				{ subject: "coaches", actions: ["read"] },
				{ subject: "divisions", actions: ["read"] },
				{ subject: "games", actions: ["read"] },
				{ subject: "brackets", actions: ["read"] },
				{ subject: "players", actions: ["read"] },
				{ subject: "roster", actions: ["read"] },
				{ subject: "teams", actions: ["read"] },
			]
		);
	}

	return rules;
}
