import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import useFormWithRemoteValidationErrors from "@/hooks/useFormWithRemoteValidationErrors";

import {
	getRemoteValidationErrors,
	getMeta,
	submittingRoutine,
	deletingRoutine,
} from "@/redux/seasonForm";

import { DEFAULT_VALUES, CONSTRAINTS } from "../constants";
import { useSeasonArchiving } from "@/components/SeasonsList";

export default function useSeasonForm(options) {
	const {
		initialValues = DEFAULT_VALUES,
		sport,
		associationId,
		leagueId,
		seasonId,
	} = options;

	const dispatch = useDispatch();
	const archiveSeason = useSeasonArchiving(seasonId);

	const { values, ...form } = useFormWithRemoteValidationErrors({
		initialValues,
		constraints: CONSTRAINTS,
		errorsSelector: getRemoteValidationErrors,
	});

	const { isSubmitting, isDeleting, isArchiving } = useSelector(getMeta);

	const submit = useCallback(
		() =>
			dispatch(
				submittingRoutine({ values, sport, associationId, leagueId, seasonId })
			),
		[dispatch, values, sport, associationId, leagueId, seasonId]
	);

	const deleteSeason = useCallback(
		() => dispatch(deletingRoutine({ associationId, leagueId, seasonId })),
		[dispatch, associationId, leagueId, seasonId]
	);

	return {
		...form,
		values,
		isSubmitting,
		isDeleting,
		isArchiving,
		submit,
		deleteSeason,
		archiveSeason,
	};
}
