import { Can, canCheck } from "@/components/Ability";
import { config } from "@/config";
import React from "react";

function getBracketNameRenderer(seasonId, otp) {
	return ({ value, data: { id } }) => {
		const displayValue = value || <span>Untitled Bracket</span>;

		const canUpdate = canCheck({
			do: "create",
			a: "scheduledGames",
			on: { seasonId },
		});

		return canUpdate ? (
			<a
				href={`${config.V5_ADMIN_DASHBOARD_URL}/seasons/${seasonId}/games/brackets/${id}?otp=${otp}`}
				target="_blank"
				rel="noopener referrer=gamesheet-brackets"
			>
				{displayValue}
			</a>
		) : (
			<span>{displayValue}</span>
		);
	};
}

export default getBracketNameRenderer;
