function isInRoleOf(roles, title, type, ...ids) {
	return !!roles.find(
		(role) =>
			role.title === title &&
			role.level.type === type &&
			ids.includes(role.level.id)
	);
}

function isAdmin(roles, type, ...ids) {
	return isInRoleOf(roles, "administrator", type, ...ids);
}

function isDirector(roles, type, ...ids) {
	return isInRoleOf(roles, "director", type, ...ids);
}

function isConvenor(roles, type, ...ids) {
	return isInRoleOf(roles, "convenor", type, ...ids);
}

function isManager(roles, type, ...ids) {
	return isInRoleOf(roles, "manager", type, ...ids);
}

function isScheduler(roles, type, ...ids) {
	return isInRoleOf(roles, "scheduler", type, ...ids);
}

function isObserver(roles, type, ...ids) {
	return isInRoleOf(roles, "observer", type, ...ids);
}

function isGuest(roles, type, ...ids) {
	return isInRoleOf(roles, "guest", type, ...ids);
}

function getIsAssociationAdmin(roles, ...associationIds) {
	return isAdmin(roles, "associations", ...associationIds);
}

function getIsLeagueAdmin(roles, ...leagueIds) {
	return isAdmin(roles, "leagues", ...leagueIds);
}

function getIsSeasonAdmin(roles, ...seasonIds) {
	return isAdmin(roles, "seasons", ...seasonIds);
}

function getIsDivisionAdmin(roles, ...divisionIds) {
	return isAdmin(roles, "divisions", ...divisionIds);
}

function getIsTeamAdmin(roles, ...teamIds) {
	return isAdmin(roles, "teams", ...teamIds);
}

function getIsAssociationDirector(roles, ...associationIds) {
	return isDirector(roles, "associations", ...associationIds);
}

function getIsLeagueDirector(roles, ...leagueIds) {
	return isDirector(roles, "leagues", ...leagueIds);
}

function getIsSeasonDirector(roles, ...seasonIds) {
	return isDirector(roles, "seasons", ...seasonIds);
}

function getIsDivisionDirector(roles, ...divisionIds) {
	return isDirector(roles, "divisions", ...divisionIds);
}

function getIsTeamDirector(roles, ...teamIds) {
	return isDirector(roles, "teams", ...teamIds);
}

function getIsAssociationConvenor(roles, ...associationIds) {
	return isConvenor(roles, "associations", ...associationIds);
}

function getIsLeagueConvenor(roles, ...leagueIds) {
	return isConvenor(roles, "leagues", ...leagueIds);
}

function getIsSeasonConvenor(roles, ...seasonIds) {
	return isConvenor(roles, "seasons", ...seasonIds);
}

function getIsDivisionConvenor(roles, ...divisionIds) {
	return isConvenor(roles, "divisions", ...divisionIds);
}

function getIsTeamConvenor(roles, ...teamIds) {
	return isConvenor(roles, "teams", ...teamIds);
}

function getIsAssociationManager(roles, ...associationIds) {
	return isManager(roles, "associations", ...associationIds);
}

function getIsLeagueManager(roles, ...leagueIds) {
	return isManager(roles, "leagues", ...leagueIds);
}

function getIsSeasonManager(roles, ...seasonIds) {
	return isManager(roles, "seasons", ...seasonIds);
}

function getIsDivisionManager(roles, ...divisionIds) {
	return isManager(roles, "divisions", ...divisionIds);
}

function getIsTeamManager(roles, ...teamIds) {
	return isManager(roles, "teams", ...teamIds);
}

function getIsAssociationScheduler(roles, ...associationIds) {
	return isScheduler(roles, "associations", ...associationIds);
}

function getIsLeagueScheduler(roles, ...leagueIds) {
	return isScheduler(roles, "leagues", ...leagueIds);
}

function getIsSeasonScheduler(roles, ...seasonIds) {
	return isScheduler(roles, "seasons", ...seasonIds);
}

function getIsDivisionScheduler(roles, ...divisionIds) {
	return isScheduler(roles, "divisions", ...divisionIds);
}

function getIsTeamScheduler(roles, ...teamIds) {
	return isScheduler(roles, "teams", ...teamIds);
}

function getIsAssociationObserver(roles, ...associationIds) {
	return isObserver(roles, "associations", ...associationIds);
}

function getIsLeagueObserver(roles, ...leagueIds) {
	return isObserver(roles, "leagues", ...leagueIds);
}

function getIsSeasonObserver(roles, ...seasonIds) {
	return isObserver(roles, "seasons", ...seasonIds);
}

function getIsDivisionObserver(roles, ...divisionIds) {
	return isObserver(roles, "divisions", ...divisionIds);
}

function getIsTeamObserver(roles, ...teamIds) {
	return isObserver(roles, "teams", ...teamIds);
}

function getIsAssociationGuest(roles, ...associationIds) {
	return isGuest(roles, "associations", ...associationIds);
}

function getIsLeagueGuest(roles, ...leagueIds) {
	return isGuest(roles, "leagues", ...leagueIds);
}

function getIsSeasonGuest(roles, ...seasonIds) {
	return isGuest(roles, "seasons", ...seasonIds);
}

function getIsDivisionGuest(roles, ...divisionIds) {
	return isGuest(roles, "divisions", ...divisionIds);
}

function getIsTeamGuest(roles, ...teamIds) {
	return isGuest(roles, "teams", ...teamIds);
}

function buildAssociationRules(roles, associations) {
	return associations.reduce((rules, { id }) => {
		const isAssociationAdmin = getIsAssociationAdmin(roles, id);
		const isAssociationDirector = getIsAssociationDirector(roles, id);
		const isAssociationConvenor = getIsAssociationConvenor(roles, id);

		if (isAssociationAdmin || isAssociationDirector || isAssociationConvenor) {
			rules.push(
				...[
					{
						subject: "associations",
						actions: ["update"],
						conditions: { id },
					},
				]
			);
		}

		if (isAssociationAdmin) {
			rules.push(
				...[
					{
						subject: "leagues",
						actions: ["create"],
						conditions: { associationId: id },
					},
				]
			);
		}

		return rules;
	}, []);
}

function buildLeaguesRules(roles, leagues) {
	return leagues.reduce((rules, league) => {
		let { id, association } = league;

		association = association || { id: 0 };

		const isAssociationAdmin = getIsAssociationAdmin(roles, association.id);
		const isAssociationDirector = getIsAssociationDirector(
			roles,
			association.id
		);
		const isAssociationConvenor = getIsAssociationConvenor(
			roles,
			association.id
		);
		const isLeagueAdmin = getIsLeagueAdmin(roles, id);
		const isLeagueDirector = getIsLeagueDirector(roles, id);
		const isLeagueConvenor = getIsLeagueConvenor(roles, id);

		if (isAssociationAdmin) {
			rules.push(
				...[
					{
						subject: "leagues",
						actions: ["update", "delete"],
						conditions: { id },
					},
				]
			);
		}

		if (
			isAssociationDirector ||
			isAssociationConvenor ||
			isLeagueAdmin ||
			isLeagueDirector ||
			isLeagueConvenor
		) {
			rules.push(
				...[
					{
						subject: "leagues",
						actions: ["update"],
						conditions: { id },
					},
				]
			);
		}

		if (isAssociationAdmin || isLeagueAdmin) {
			rules.push(
				...[
					{
						subject: "seasons",
						actions: ["create"],
						conditions: { leagueId: id },
					},
				]
			);
		}

		return rules;
	}, []);
}

function buildSeasonsRules(roles, seasons) {
	return seasons.reduce((rules, season) => {
		let { id, association, league } = season;

		association = association || { id: 0 };
		league = league || { id: 0 };

		const isAssociationAdmin = getIsAssociationAdmin(roles, association.id);
		const isAssociationDirector = getIsAssociationDirector(
			roles,
			association.id
		);
		const isAssociationConvenor = getIsAssociationConvenor(
			roles,
			association.id
		);
		const isAssociationManager = getIsAssociationManager(roles, association.id);
		const isAssociationScheduler = getIsAssociationScheduler(
			roles,
			association.id
		);
		const isAssociationObserver = getIsAssociationObserver(
			roles,
			association.id
		);
		const isAssociationGuest = getIsAssociationGuest(roles, association.id);
		const isLeagueAdmin = getIsLeagueAdmin(roles, league.id);
		const isLeagueDirector = getIsLeagueDirector(roles, league.id);
		const isLeagueConvenor = getIsLeagueConvenor(roles, league.id);
		const isLeagueManager = getIsLeagueManager(roles, league.id);
		const isLeagueScheduler = getIsLeagueScheduler(roles, league.id);
		const isLeagueObserver = getIsLeagueObserver(roles, league.id);
		const isLeagueGuest = getIsLeagueGuest(roles, league.id);
		const isSeasonAdmin = getIsSeasonAdmin(roles, id);
		const isSeasonDirector = getIsSeasonDirector(roles, id);
		const isSeasonConvenor = getIsSeasonConvenor(roles, id);
		const isSeasonManager = getIsSeasonManager(roles, id);
		const isSeasonScheduler = getIsSeasonScheduler(roles, id);
		const isSeasonObserver = getIsSeasonObserver(roles, id);
		const isSeasonGuest = getIsSeasonGuest(roles, id);

		if (
			isAssociationAdmin ||
			isAssociationDirector ||
			isAssociationConvenor ||
			isAssociationManager ||
			isAssociationObserver ||
			isAssociationGuest ||
			isLeagueAdmin ||
			isLeagueDirector ||
			isLeagueConvenor ||
			isLeagueManager ||
			isLeagueObserver ||
			isLeagueGuest ||
			isSeasonAdmin ||
			isSeasonDirector ||
			isSeasonConvenor ||
			isSeasonManager ||
			isSeasonObserver ||
			isSeasonGuest
		) {
			rules.push(
				...[
					{
						subject: "roster",
						actions: ["read"],
						conditions: { seasonId: id },
					},
				]
			);
		}

		if (isAssociationAdmin || isLeagueAdmin) {
			rules.push(
				...[
					{
						subject: "seasons",
						actions: ["delete"],
						conditions: { id },
					},
				]
			);
		}

		if (
			isAssociationAdmin ||
			isAssociationDirector ||
			isAssociationConvenor ||
			isAssociationManager ||
			isLeagueAdmin ||
			isLeagueDirector ||
			isLeagueConvenor ||
			isLeagueManager ||
			isSeasonAdmin ||
			isSeasonDirector ||
			isSeasonConvenor ||
			isSeasonManager
		) {
			rules.push(
				...[
					{
						subject: "roster",
						actions: ["import"],
						conditions: { seasonId: id },
					},
				]
			);
		}

		if (
			isAssociationAdmin ||
			isAssociationDirector ||
			isLeagueAdmin ||
			isLeagueDirector ||
			isSeasonAdmin ||
			isSeasonDirector
		) {
			rules.push(
				...[
					{
						subject: "seasons",
						actions: ["update", "import"],
						conditions: { id },
					},
					{
						subject: "divisions",
						actions: ["create"],
						conditions: { seasonId: id },
					},
					{
						subject: "teams",
						actions: ["create", "addPlayer", "addCoach"],
						conditions: { seasonId: id },
					},
					{
						subject: "players",
						actions: ["create", "merge"],
						conditions: { seasonId: id },
					},
					{
						subject: "coaches",
						actions: ["create", "merge"],
						conditions: { seasonId: id },
					},
					{
						subject: "referees",
						actions: ["create", "read", "update", "delete", "merge"],
						conditions: { seasonId: id },
					},
					{
						subject: "gameNotes",
						actions: ["read"],
						conditions: { seasonId: id },
					},
					{
						subject: "gameRefereeReports",
						actions: ["read"],
						conditions: { seasonId: id },
					},
				]
			);
		}

		if (isAssociationConvenor || isLeagueConvenor || isSeasonConvenor) {
			rules.push(
				...[
					{
						subject: "seasons",
						actions: ["update"],
						conditions: { id },
					},
					{
						subject: "divisions",
						actions: ["create"],
						conditions: { seasonId: id },
					},
					{
						subject: "teams",
						actions: ["create", "addPlayer", "addCoach"],
						conditions: { seasonId: id },
					},
					{
						subject: "players",
						actions: ["create", "merge"],
						conditions: { seasonId: id },
					},
					{
						subject: "coaches",
						actions: ["create", "merge"],
						conditions: { seasonId: id },
					},
					{
						subject: "referees",
						actions: ["create", "read", "update", "delete", "merge"],
						conditions: { seasonId: id },
					},
					{
						subject: "gameNotes",
						actions: ["read"],
						conditions: { seasonId: id },
					},
					{
						subject: "gameRefereeReports",
						actions: ["read"],
						conditions: { seasonId: id },
					},
				]
			);
		}

		if (isAssociationObserver || isLeagueObserver || isSeasonObserver) {
			rules.push(
				...[
					{
						subject: "gameNotes",
						actions: ["read"],
						conditions: { seasonId: id },
					},
					{
						subject: "gameRefereeReports",
						actions: ["read"],
						conditions: { seasonId: id },
					},
					{
						subject: "referees",
						actions: ["read"],
						conditions: { seasonId: id },
					},
				]
			);
		}

		if (isAssociationManager || isLeagueManager || isSeasonManager) {
			rules.push(
				...[
					{
						subject: "players",
						actions: ["create"],
						conditions: { seasonId: id },
					},
					{
						subject: "coaches",
						actions: ["create"],
						conditions: { seasonId: id },
					},
				]
			);
		}

		if (
			isAssociationAdmin ||
			isAssociationDirector ||
			isAssociationConvenor ||
			isAssociationManager ||
			isAssociationScheduler ||
			isLeagueAdmin ||
			isLeagueDirector ||
			isLeagueConvenor ||
			isLeagueManager ||
			isLeagueScheduler ||
			isSeasonAdmin ||
			isSeasonDirector ||
			isSeasonConvenor ||
			isSeasonManager ||
			isSeasonScheduler
		) {
			rules.push(
				...[
					{
						subject: "scheduledGames",
						actions: ["create"],
						conditions: { seasonId: id },
					},
				]
			);
		}

		if (
			isAssociationAdmin ||
			isAssociationDirector ||
			isAssociationConvenor ||
			isAssociationScheduler ||
			isLeagueAdmin ||
			isLeagueDirector ||
			isLeagueConvenor ||
			isLeagueScheduler ||
			isSeasonAdmin ||
			isSeasonDirector ||
			isSeasonConvenor ||
			isSeasonScheduler
		) {
			rules.push(
				...[
					{
						subject: "brackets",
						actions: ["create"],
						conditions: { seasonId: id },
					},
				]
			);
		}

		return rules;
	}, []);
}

function buildArchivedSeasonsRules(roles, seasons) {
	return seasons.reduce((rules, season) => {
		let { id, association, league } = season;

		association = association || { id: 0 };
		league = league || { id: 0 };

		const isAssociationAdmin = getIsAssociationAdmin(roles, association.id);
		const isAssociationDirector = getIsAssociationDirector(
			roles,
			association.id
		);
		const isAssociationConvenor = getIsAssociationConvenor(
			roles,
			association.id
		);
		const isLeagueAdmin = getIsLeagueAdmin(roles, league.id);
		const isLeagueDirector = getIsLeagueDirector(roles, league.id);
		const isLeagueConvenor = getIsLeagueConvenor(roles, league.id);
		const isSeasonAdmin = getIsSeasonAdmin(roles, id);
		const isSeasonDirector = getIsSeasonDirector(roles, id);
		const isSeasonConvenor = getIsSeasonConvenor(roles, id);

		if (
			isAssociationAdmin ||
			isAssociationDirector ||
			isAssociationConvenor ||
			isLeagueAdmin ||
			isLeagueDirector ||
			isLeagueConvenor ||
			isSeasonAdmin ||
			isSeasonDirector ||
			isSeasonConvenor
		) {
			rules.push(
				...[
					{
						subject: "seasons",
						actions: ["update"],
						conditions: { id },
					},
				]
			);
		}

		return rules;
	}, []);
}

function buildGamesRules(roles, games) {
	return games.reduce((rules, game) => {
		let { id, association, league, season } = game;

		association = association || { id: 0 };
		league = league || { id: 0 };
		season = season || { id: 0 };

		const homeDivisionId =
			game.home && game.home.division && game.home.division.id;

		const visitorDivisionId =
			game.visitor && game.visitor.division && game.visitor.division.id;

		const homeId = game.home && game.home.id;
		const visitorId = game.visitor && game.visitor.id;

		const isAssociationAdmin = getIsAssociationAdmin(roles, association.id);
		const isAssociationDirector = getIsAssociationDirector(
			roles,
			association.id
		);
		const isAssociationConvenor = getIsAssociationConvenor(
			roles,
			association.id
		);
		const isAssociationManager = getIsAssociationManager(roles, association.id);
		const isAssociationScheduler = getIsAssociationScheduler(
			roles,
			association.id
		);
		const isAssociationObserver = getIsAssociationObserver(
			roles,
			association.id
		);
		const isAssociationGuest = getIsAssociationGuest(roles, association.id);
		const isLeagueAdmin = getIsLeagueAdmin(roles, league.id);
		const isLeagueDirector = getIsLeagueDirector(roles, league.id);
		const isLeagueConvenor = getIsLeagueConvenor(roles, league.id);
		const isLeagueManager = getIsLeagueManager(roles, league.id);
		const isLeagueScheduler = getIsLeagueScheduler(roles, league.id);
		const isLeagueObserver = getIsLeagueObserver(roles, league.id);
		const isLeagueGuest = getIsLeagueGuest(roles, league.id);
		const isSeasonAdmin = getIsSeasonAdmin(roles, season.id);
		const isSeasonDirector = getIsSeasonDirector(roles, season.id);
		const isSeasonConvenor = getIsSeasonConvenor(roles, season.id);
		const isSeasonManager = getIsSeasonManager(roles, season.id);
		const isSeasonScheduler = getIsSeasonScheduler(roles, season.id);
		const isSeasonObserver = getIsSeasonObserver(roles, season.id);
		const isSeasonGuest = getIsSeasonGuest(roles, season.id);
		const isDivisionAdmin = getIsDivisionAdmin(
			roles,
			homeDivisionId,
			visitorDivisionId
		);
		const isDivisionDirector = getIsDivisionDirector(
			roles,
			homeDivisionId,
			visitorDivisionId
		);
		const isDivisionConvenor = getIsDivisionConvenor(
			roles,
			homeDivisionId,
			visitorDivisionId
		);
		const isDivisionManager = getIsDivisionManager(
			roles,
			homeDivisionId,
			visitorDivisionId
		);
		const isDivisionScheduler = getIsDivisionScheduler(
			roles,
			homeDivisionId,
			visitorDivisionId
		);
		const isDivisionObserver = getIsDivisionObserver(
			roles,
			homeDivisionId,
			visitorDivisionId
		);
		const isDivisionGuest = getIsDivisionGuest(
			roles,
			homeDivisionId,
			visitorDivisionId
		);
		const isTeamAdmin = getIsTeamAdmin(roles, homeId, visitorId);
		const isTeamDirector = getIsTeamDirector(roles, homeId, visitorId);
		const isTeamConvenor = getIsTeamConvenor(roles, homeId, visitorId);
		const isTeamManager = getIsTeamManager(roles, homeId, visitorId);
		const isTeamScheduler = getIsTeamScheduler(roles, homeId, visitorId);
		const isTeamObserver = getIsTeamObserver(roles, homeId, visitorId);
		const isTeamGuest = getIsTeamGuest(roles, homeId, visitorId);

		if (
			isAssociationAdmin ||
			isAssociationDirector ||
			isAssociationConvenor ||
			isAssociationManager ||
			isAssociationScheduler ||
			isAssociationObserver ||
			isAssociationGuest ||
			isLeagueAdmin ||
			isLeagueDirector ||
			isLeagueConvenor ||
			isLeagueManager ||
			isLeagueScheduler ||
			isLeagueObserver ||
			isLeagueGuest ||
			isSeasonAdmin ||
			isSeasonDirector ||
			isSeasonConvenor ||
			isSeasonManager ||
			isSeasonScheduler ||
			isSeasonObserver ||
			isSeasonGuest ||
			isDivisionAdmin ||
			isDivisionDirector ||
			isDivisionConvenor ||
			isDivisionManager ||
			isDivisionScheduler ||
			isDivisionObserver ||
			isDivisionGuest ||
			isTeamAdmin ||
			isTeamDirector ||
			isTeamConvenor ||
			isTeamManager ||
			isTeamScheduler ||
			isTeamObserver ||
			isTeamGuest
		) {
			rules.push(
				...[
					{
						subject: "games",
						actions: ["read"],
						conditions: { id },
					},
				]
			);
		}

		if (
			isAssociationAdmin ||
			isAssociationDirector ||
			isLeagueAdmin ||
			isLeagueDirector ||
			isSeasonAdmin ||
			isSeasonDirector ||
			isDivisionAdmin ||
			isDivisionDirector ||
			isTeamAdmin ||
			isTeamDirector
		) {
			rules.push(
				...[
					{
						subject: "games",
						actions: ["update", "delete"],
						conditions: { id },
					},
				]
			);
		}

		if (
			isAssociationAdmin ||
			isAssociationDirector ||
			isAssociationConvenor ||
			isAssociationObserver ||
			isLeagueConvenor ||
			isLeagueAdmin ||
			isLeagueDirector ||
			isLeagueObserver ||
			isSeasonAdmin ||
			isSeasonDirector ||
			isSeasonConvenor ||
			isSeasonObserver ||
			isDivisionAdmin ||
			isDivisionDirector ||
			isDivisionObserver ||
			isDivisionConvenor ||
			isTeamAdmin ||
			isTeamDirector ||
			isTeamConvenor ||
			isTeamObserver
		) {
			rules.push(
				...[
					{
						subject: "gameNotes",
						actions: ["create", "read"],
						conditions: { gameId: id },
					},
				]
			);
		}

		if (isTeamManager) {
			rules.push(
				...[
					{
						subject: "player-of-the-game",
						actions: ["create"],
						conditions: { gameId: id },
					},
				]
			);
		}

		return rules;
	}, []);
}

function buildScheduledGamesRules(roles, games) {
	return games.reduce((rules, game) => {
		let { id, association, league, season } = game;

		association = association || { id: 0 };
		league = league || { id: 0 };
		season = season || { id: 0 };

		const homeDivisionId = game.homeDivision && game.homeDivision.id;
		const visitorDivisionId = game.visitorDivision && game.visitorDivision.id;
		const homeId = game.homeTeam && game.homeTeam.id;
		const visitorId = game.visitorTeam && game.visitorTeam.id;

		const isAssociationAdmin = getIsAssociationAdmin(roles, association.id);
		const isAssociationDirector = getIsAssociationDirector(
			roles,
			association.id
		);
		const isAssociationConvenor = getIsAssociationConvenor(
			roles,
			association.id
		);
		const isAssociationManager = getIsAssociationManager(roles, association.id);
		const isAssociationScheduler = getIsAssociationScheduler(
			roles,
			association.id
		);
		const isLeagueAdmin = getIsLeagueAdmin(roles, league.id);
		const isLeagueDirector = getIsLeagueDirector(roles, league.id);
		const isLeagueConvenor = getIsLeagueConvenor(roles, league.id);
		const isLeagueManager = getIsLeagueManager(roles, league.id);
		const isLeagueScheduler = getIsLeagueScheduler(roles, league.id);
		const isSeasonAdmin = getIsSeasonAdmin(roles, season.id);
		const isSeasonDirector = getIsSeasonDirector(roles, season.id);
		const isSeasonConvenor = getIsSeasonConvenor(roles, season.id);
		const isSeasonManager = getIsSeasonManager(roles, season.id);
		const isSeasonScheduler = getIsSeasonScheduler(roles, season.id);
		const isDivisionAdmin = getIsDivisionAdmin(
			roles,
			homeDivisionId,
			visitorDivisionId
		);
		const isDivisionDirector = getIsDivisionDirector(
			roles,
			homeDivisionId,
			visitorDivisionId
		);
		const isDivisionConvenor = getIsDivisionConvenor(
			roles,
			homeDivisionId,
			visitorDivisionId
		);
		const isDivisionManager = getIsDivisionManager(
			roles,
			homeDivisionId,
			visitorDivisionId
		);
		const isDivisionScheduler = getIsDivisionScheduler(
			roles,
			homeDivisionId,
			visitorDivisionId
		);
		const isTeamAdmin = getIsTeamAdmin(roles, homeId, visitorId);
		const isTeamDirector = getIsTeamDirector(roles, homeId, visitorId);
		const isTeamConvenor = getIsTeamConvenor(roles, homeId, visitorId);
		const isTeamScheduler = getIsTeamScheduler(roles, homeId, visitorId);

		if (
			isAssociationAdmin ||
			isAssociationDirector ||
			isAssociationConvenor ||
			isAssociationManager ||
			isAssociationScheduler ||
			isLeagueAdmin ||
			isLeagueDirector ||
			isLeagueConvenor ||
			isLeagueManager ||
			isLeagueScheduler ||
			isSeasonAdmin ||
			isSeasonDirector ||
			isSeasonConvenor ||
			isSeasonManager ||
			isSeasonScheduler ||
			isDivisionAdmin ||
			isDivisionDirector ||
			isDivisionConvenor ||
			isDivisionManager ||
			isDivisionScheduler ||
			isTeamAdmin ||
			isTeamDirector ||
			isTeamConvenor ||
			isTeamScheduler
		) {
			rules.push(
				...[
					{
						subject: "scheduledGames",
						actions: ["update"],
						conditions: { id },
					},
				]
			);
		}

		if (
			isAssociationAdmin ||
			isAssociationDirector ||
			isAssociationConvenor ||
			isAssociationScheduler ||
			isLeagueAdmin ||
			isLeagueDirector ||
			isLeagueConvenor ||
			isLeagueScheduler ||
			isSeasonAdmin ||
			isSeasonDirector ||
			isSeasonConvenor ||
			isSeasonScheduler ||
			isDivisionAdmin ||
			isDivisionDirector ||
			isDivisionConvenor ||
			isDivisionScheduler ||
			isTeamAdmin ||
			isTeamDirector ||
			isTeamConvenor ||
			isTeamScheduler
		) {
			rules.push(
				...[
					{
						subject: "scheduledGames",
						actions: ["delete"],
						conditions: { id },
					},
				]
			);
		}

		return rules;
	}, []);
}

function buildBracketRules(roles, games) {
	return games.reduce((rules, game) => {
		let { id, association, league, season } = game;

		association = association || { id: 0 };
		league = league || { id: 0 };
		season = season || { id: 0 };

		const homeDivisionId = game.homeDivision && game.homeDivision.id;
		const visitorDivisionId = game.visitorDivision && game.visitorDivision.id;
		const homeId = game.homeTeam && game.homeTeam.id;
		const visitorId = game.visitorTeam && game.visitorTeam.id;

		const isAssociationAdmin = getIsAssociationAdmin(roles, association.id);
		const isAssociationDirector = getIsAssociationDirector(
			roles,
			association.id
		);
		const isAssociationConvenor = getIsAssociationConvenor(
			roles,
			association.id
		);
		const isAssociationManager = getIsAssociationManager(roles, association.id);
		const isAssociationScheduler = getIsAssociationScheduler(
			roles,
			association.id
		);
		const isLeagueAdmin = getIsLeagueAdmin(roles, league.id);
		const isLeagueDirector = getIsLeagueDirector(roles, league.id);
		const isLeagueConvenor = getIsLeagueConvenor(roles, league.id);
		const isLeagueManager = getIsLeagueManager(roles, league.id);
		const isLeagueScheduler = getIsLeagueScheduler(roles, league.id);
		const isSeasonAdmin = getIsSeasonAdmin(roles, season.id);
		const isSeasonDirector = getIsSeasonDirector(roles, season.id);
		const isSeasonConvenor = getIsSeasonConvenor(roles, season.id);
		const isSeasonManager = getIsSeasonManager(roles, season.id);
		const isSeasonScheduler = getIsSeasonScheduler(roles, season.id);
		const isDivisionAdmin = getIsDivisionAdmin(
			roles,
			homeDivisionId,
			visitorDivisionId
		);
		const isDivisionDirector = getIsDivisionDirector(
			roles,
			homeDivisionId,
			visitorDivisionId
		);
		const isDivisionConvenor = getIsDivisionConvenor(
			roles,
			homeDivisionId,
			visitorDivisionId
		);
		const isDivisionManager = getIsDivisionManager(
			roles,
			homeDivisionId,
			visitorDivisionId
		);
		const isDivisionScheduler = getIsDivisionScheduler(
			roles,
			homeDivisionId,
			visitorDivisionId
		);
		const isTeamAdmin = getIsTeamAdmin(roles, homeId, visitorId);
		const isTeamDirector = getIsTeamDirector(roles, homeId, visitorId);
		const isTeamConvenor = getIsTeamConvenor(roles, homeId, visitorId);
		const isTeamScheduler = getIsTeamScheduler(roles, homeId, visitorId);

		if (
			isAssociationAdmin ||
			isAssociationDirector ||
			isAssociationConvenor ||
			isAssociationScheduler ||
			isLeagueAdmin ||
			isLeagueDirector ||
			isLeagueConvenor ||
			isLeagueScheduler ||
			isSeasonAdmin ||
			isSeasonDirector ||
			isSeasonConvenor ||
			isSeasonScheduler ||
			isDivisionAdmin ||
			isDivisionDirector ||
			isDivisionConvenor ||
			isDivisionScheduler ||
			isTeamAdmin ||
			isTeamDirector ||
			isTeamConvenor ||
			isTeamScheduler
		) {
			rules.push(
				...[
					{
						subject: "brackets",
						actions: ["update"],
						conditions: { id },
					},
				]
			);
		}

		if (
			isAssociationAdmin ||
			isAssociationDirector ||
			isAssociationConvenor ||
			isAssociationScheduler ||
			isLeagueAdmin ||
			isLeagueDirector ||
			isLeagueConvenor ||
			isLeagueScheduler ||
			isSeasonAdmin ||
			isSeasonDirector ||
			isSeasonConvenor ||
			isSeasonScheduler ||
			isDivisionAdmin ||
			isDivisionDirector ||
			isDivisionConvenor ||
			isDivisionScheduler ||
			isTeamAdmin ||
			isTeamDirector ||
			isTeamConvenor ||
			isTeamScheduler
		) {
			rules.push(
				...[
					{
						subject: "brackets",
						actions: ["delete"],
						conditions: { id },
					},
				]
			);
		}

		return rules;
	}, []);
}

function buildSuspensionReportsRules(roles, reports) {
	return reports.reduce((rules, report) => {
		let {
			association,
			league,
			season,
			suspensionReportGame: game,
			suspensionReportTeam: team,
		} = report;

		let { division } = team;

		association = association || { id: 0 };
		league = league || { id: 0 };
		season = season || { id: 0 };
		division = division || { id: 0 };
		game = game || { id: 0 };
		team = team || { id: 0 };

		const isAssociationAdmin = getIsAssociationAdmin(roles, association.id);
		const isAssociationDirector = getIsAssociationDirector(
			roles,
			association.id
		);
		const isAssociationConvenor = getIsAssociationConvenor(
			roles,
			association.id
		);
		const isAssociationManager = getIsAssociationManager(roles, association.id);
		const isAssociationScheduler = getIsAssociationScheduler(
			roles,
			association.id
		);
		const isAssociationObserver = getIsAssociationObserver(
			roles,
			association.id
		);
		const isAssociationGuest = getIsAssociationGuest(roles, association.id);
		const isLeagueAdmin = getIsLeagueAdmin(roles, league.id);
		const isLeagueDirector = getIsLeagueDirector(roles, league.id);
		const isLeagueConvenor = getIsLeagueConvenor(roles, league.id);
		const isLeagueManager = getIsLeagueManager(roles, league.id);
		const isLeagueScheduler = getIsLeagueScheduler(roles, league.id);
		const isLeagueObserver = getIsLeagueObserver(roles, league.id);
		const isLeagueGuest = getIsLeagueGuest(roles, league.id);
		const isSeasonAdmin = getIsSeasonAdmin(roles, season.id);
		const isSeasonDirector = getIsSeasonDirector(roles, season.id);
		const isSeasonConvenor = getIsSeasonConvenor(roles, season.id);
		const isSeasonManager = getIsSeasonManager(roles, season.id);
		const isSeasonScheduler = getIsSeasonScheduler(roles, season.id);
		const isSeasonObserver = getIsSeasonObserver(roles, season.id);
		const isSeasonGuest = getIsSeasonGuest(roles, season.id);
		const isDivisionAdmin = getIsDivisionAdmin(roles, division.id);
		const isDivisionDirector = getIsDivisionDirector(roles, division.id);
		const isDivisionConvenor = getIsDivisionConvenor(roles, division.id);
		const isDivisionManager = getIsDivisionManager(roles, division.id);
		const isDivisionScheduler = getIsDivisionScheduler(roles, division.id);
		const isDivisionObserver = getIsDivisionObserver(roles, division.id);
		const isDivisionGuest = getIsDivisionGuest(roles, division.id);
		const isTeamAdmin = getIsTeamAdmin(roles, team.id);
		const isTeamDirector = getIsTeamDirector(roles, team.id);
		const isTeamConvenor = getIsTeamConvenor(roles, team.id);
		const isTeamManager = getIsTeamManager(roles, team.id);
		const isTeamScheduler = getIsTeamScheduler(roles, team.id);
		const isTeamObserver = getIsTeamObserver(roles, team.id);
		const isTeamGuest = getIsTeamGuest(roles, team.id);

		if (
			isAssociationAdmin ||
			isAssociationDirector ||
			isAssociationConvenor ||
			isAssociationManager ||
			isAssociationScheduler ||
			isAssociationObserver ||
			isAssociationGuest ||
			isLeagueAdmin ||
			isLeagueDirector ||
			isLeagueConvenor ||
			isLeagueManager ||
			isLeagueScheduler ||
			isLeagueObserver ||
			isLeagueGuest ||
			isSeasonAdmin ||
			isSeasonDirector ||
			isSeasonConvenor ||
			isSeasonManager ||
			isSeasonScheduler ||
			isSeasonObserver ||
			isSeasonGuest ||
			isDivisionAdmin ||
			isDivisionDirector ||
			isDivisionConvenor ||
			isDivisionManager ||
			isDivisionScheduler ||
			isDivisionObserver ||
			isDivisionGuest ||
			isTeamAdmin ||
			isTeamDirector ||
			isTeamConvenor ||
			isTeamManager ||
			isTeamScheduler ||
			isTeamObserver ||
			isTeamGuest
		) {
			rules.push(
				...[
					{
						subject: "games",
						actions: ["read"],
						conditions: { id: game.id },
					},
				]
			);
		}

		return rules;
	}, []);
}

function buildDivisionsRules(roles, divisions) {
	return divisions.reduce((rules, division) => {
		let { id, association, league, season } = division;

		association = association || { id: 0 };
		league = league || { id: 0 };
		season = season || { id: 0 };

		const isAssociationAdmin = getIsAssociationAdmin(roles, association.id);
		const isAssociationDirector = getIsAssociationDirector(
			roles,
			association.id
		);
		const isAssociationConvenor = getIsAssociationConvenor(
			roles,
			association.id
		);
		const isLeagueAdmin = getIsLeagueAdmin(roles, league.id);
		const isLeagueDirector = getIsLeagueDirector(roles, league.id);
		const isLeagueConvenor = getIsLeagueConvenor(roles, league.id);
		const isSeasonAdmin = getIsSeasonAdmin(roles, season.id);
		const isSeasonDirector = getIsSeasonDirector(roles, season.id);
		const isSeasonConvenor = getIsSeasonConvenor(roles, season.id);
		const isDivisionAdmin = getIsDivisionAdmin(roles, id);
		const isDivisionDirector = getIsDivisionDirector(roles, id);
		const isDivisionConvenor = getIsDivisionConvenor(roles, id);
		const isDivisionManager = getIsDivisionManager(roles, id);
		const isDivisionScheduler = getIsDivisionScheduler(roles, id);

		if (isAssociationAdmin || isLeagueAdmin) {
			rules.push(
				...[
					{
						subject: "teams",
						actions: ["copy"],
						conditions: { divisionId: id },
					},
				]
			);
		}

		if (
			isAssociationAdmin ||
			isAssociationDirector ||
			isLeagueAdmin ||
			isLeagueDirector ||
			isSeasonAdmin ||
			isSeasonDirector
		) {
			rules.push(
				...[
					{
						subject: "divisions",
						actions: ["delete"],
						conditions: { id },
					},
					{
						subject: "teamLocks",
						actions: ["ignore"],
					},
				]
			);
		}

		if (isDivisionAdmin || isDivisionDirector) {
			rules.push(
				...[
					{
						subject: "teamLocks",
						actions: ["ignore"],
					},
				]
			);
		}

		if (
			isAssociationAdmin ||
			isAssociationDirector ||
			isAssociationConvenor ||
			isLeagueAdmin ||
			isLeagueDirector ||
			isLeagueConvenor ||
			isSeasonAdmin ||
			isSeasonDirector ||
			isSeasonConvenor ||
			isDivisionAdmin ||
			isDivisionDirector ||
			isDivisionConvenor
		) {
			rules.push(
				...[
					{
						subject: "divisions",
						actions: ["update"],
						conditions: { id },
					},
					{
						subject: "teams",
						actions: ["create"],
						conditions: { divisionId: id },
					},
					{
						subject: "teamLocks",
						actions: ["create", "update", "delete"],
					},
				]
			);
		}

		if (
			isDivisionAdmin ||
			isDivisionDirector ||
			isDivisionConvenor ||
			isDivisionManager ||
			isDivisionScheduler
		) {
			rules.push(
				...[
					{
						subject: "scheduledGames",
						actions: ["create"],
						conditions: { seasonId: season.id },
					},
					{
						subject: "brackets",
						actions: ["create"],
						conditions: { seasonId: season.id },
					},
				]
			);
		}

		return rules;
	}, []);
}

function buildTeamsRules(roles, teams) {
	return teams.reduce((rules, team) => {
		let { id, association, league, season, division } = team;

		association = association || { id: 0 };
		league = league || { id: 0 };
		season = season || { id: 0 };
		division = division || { id: 0 };

		const isAssociationAdmin = getIsAssociationAdmin(roles, association.id);
		const isAssociationDirector = getIsAssociationDirector(
			roles,
			association.id
		);
		const isAssociationConvenor = getIsAssociationConvenor(
			roles,
			association.id
		);
		const isAssociationManager = getIsAssociationManager(roles, association.id);
		const isAssociationScheduler = getIsAssociationScheduler(
			roles,
			association.id
		);
		const isAssociationObserver = getIsAssociationObserver(
			roles,
			association.id
		);
		const isAssociationGuest = getIsAssociationGuest(roles, association.id);
		const isLeagueAdmin = getIsLeagueAdmin(roles, league.id);
		const isLeagueDirector = getIsLeagueDirector(roles, league.id);
		const isLeagueConvenor = getIsLeagueConvenor(roles, league.id);
		const isLeagueManager = getIsLeagueManager(roles, league.id);
		const isLeagueScheduler = getIsLeagueScheduler(roles, league.id);
		const isLeagueObserver = getIsLeagueObserver(roles, league.id);
		const isLeagueGuest = getIsLeagueGuest(roles, league.id);
		const isSeasonAdmin = getIsSeasonAdmin(roles, season.id);
		const isSeasonDirector = getIsSeasonDirector(roles, season.id);
		const isSeasonConvenor = getIsSeasonConvenor(roles, season.id);
		const isSeasonManager = getIsSeasonManager(roles, season.id);
		const isSeasonScheduler = getIsSeasonScheduler(roles, season.id);
		const isSeasonObserver = getIsSeasonObserver(roles, season.id);
		const isSeasonGuest = getIsSeasonGuest(roles, season.id);
		const isDivisionAdmin = getIsDivisionAdmin(roles, division.id);
		const isDivisionDirector = getIsDivisionDirector(roles, division.id);
		const isDivisionConvenor = getIsDivisionConvenor(roles, division.id);
		const isDivisionManager = getIsDivisionManager(roles, division.id);
		const isDivisionScheduler = getIsDivisionScheduler(roles, division.id);
		const isDivisionObserver = getIsDivisionObserver(roles, division.id);
		const isDivisionGuest = getIsDivisionGuest(roles, division.id);
		const isTeamAdmin = getIsTeamAdmin(roles, id);
		const isTeamDirector = getIsTeamDirector(roles, id);
		const isTeamConvenor = getIsTeamConvenor(roles, id);
		const isTeamManager = getIsTeamManager(roles, id);
		const isTeamScheduler = getIsTeamScheduler(roles, id);
		const isTeamObserver = getIsTeamObserver(roles, id);
		const isTeamGuest = getIsTeamGuest(roles, id);

		if (
			isAssociationAdmin ||
			isAssociationDirector ||
			isAssociationConvenor ||
			isAssociationManager ||
			isAssociationScheduler ||
			isAssociationObserver ||
			isAssociationGuest ||
			isLeagueAdmin ||
			isLeagueDirector ||
			isLeagueConvenor ||
			isLeagueManager ||
			isLeagueScheduler ||
			isLeagueObserver ||
			isLeagueGuest ||
			isSeasonAdmin ||
			isSeasonDirector ||
			isSeasonConvenor ||
			isSeasonManager ||
			isSeasonScheduler ||
			isSeasonObserver ||
			isSeasonGuest ||
			isDivisionAdmin ||
			isDivisionDirector ||
			isDivisionConvenor ||
			isDivisionManager ||
			isDivisionScheduler ||
			isDivisionObserver ||
			isDivisionGuest ||
			isTeamAdmin ||
			isTeamDirector ||
			isTeamConvenor ||
			isTeamManager ||
			isTeamObserver ||
			isTeamGuest ||
			isTeamScheduler
		) {
			rules.push(
				...[
					{
						subject: "teams",
						actions: ["read"],
						conditions: { id },
					},
					{
						subject: "divisions",
						actions: ["read"],
						conditions: { id: division.id },
					},
				]
			);
		}

		if (
			isAssociationAdmin ||
			isAssociationDirector ||
			isLeagueAdmin ||
			isLeagueDirector ||
			isSeasonAdmin ||
			isSeasonDirector ||
			isDivisionAdmin ||
			isDivisionDirector ||
			isTeamAdmin ||
			isTeamDirector
		) {
			rules.push(
				...[
					{
						subject: "teamLocks",
						actions: ["create", "update", "delete", "ignore"],
					},
				]
			);
		}

		if (
			isAssociationConvenor ||
			isLeagueConvenor ||
			isSeasonConvenor ||
			isDivisionConvenor ||
			isTeamConvenor
		) {
			rules.push(
				...[
					{
						subject: "teamLocks",
						actions: ["create", "update", "delete"],
					},
				]
			);
		}

		if (
			isAssociationAdmin ||
			isAssociationDirector ||
			isLeagueAdmin ||
			isLeagueDirector ||
			isSeasonAdmin ||
			isSeasonDirector ||
			isDivisionAdmin ||
			isDivisionDirector
		) {
			rules.push(
				...[
					{
						subject: "teams",
						actions: ["delete"],
						conditions: { id },
					},
				]
			);
		}

		if (
			isAssociationAdmin ||
			isLeagueAdmin ||
			isSeasonAdmin ||
			isDivisionAdmin ||
			isTeamAdmin ||
			isAssociationDirector ||
			isLeagueDirector ||
			isSeasonDirector ||
			isDivisionDirector ||
			isTeamDirector ||
			isAssociationConvenor ||
			isLeagueConvenor ||
			isSeasonConvenor ||
			isDivisionConvenor ||
			isTeamConvenor ||
			isAssociationManager ||
			isLeagueManager ||
			isSeasonManager ||
			isDivisionManager ||
			isTeamManager
		) {
			rules.push(
				...[
					{
						subject: "roster",
						actions: ["import"],
						conditions: { teamId: id },
					},
				]
			);
		}

		if (
			isAssociationAdmin ||
			isAssociationDirector ||
			isAssociationConvenor ||
			isAssociationManager ||
			isLeagueAdmin ||
			isLeagueDirector ||
			isLeagueConvenor ||
			isLeagueManager ||
			isSeasonAdmin ||
			isSeasonDirector ||
			isSeasonConvenor ||
			isSeasonManager ||
			isDivisionAdmin ||
			isDivisionDirector ||
			isDivisionConvenor ||
			isDivisionManager ||
			isTeamAdmin ||
			isTeamDirector ||
			isTeamConvenor ||
			isTeamManager
		) {
			rules.push(
				...[
					{
						subject: "teams",
						actions: ["read", "update"],
						conditions: { id },
					},
					{
						subject: "roster",
						actions: [
							"addPlayer",
							"addCoach",
							"createPlayer",
							"createCoach",
							"update",
						],
						conditions: { teamId: id },
					},
				]
			);
		}

		if (
			isAssociationObserver ||
			isAssociationGuest ||
			isLeagueObserver ||
			isLeagueGuest ||
			isSeasonObserver ||
			isSeasonGuest ||
			isDivisionObserver ||
			isDivisionGuest ||
			isTeamObserver ||
			isTeamGuest
		) {
			rules.push(
				...[
					{
						subject: "teams",
						actions: ["read"],
						conditions: { id },
					},
				]
			);
		}

		if (
			isAssociationAdmin ||
			isAssociationDirector ||
			isAssociationConvenor ||
			isAssociationManager ||
			isAssociationObserver ||
			isLeagueAdmin ||
			isLeagueDirector ||
			isLeagueConvenor ||
			isLeagueManager ||
			isLeagueObserver ||
			isSeasonAdmin ||
			isSeasonDirector ||
			isSeasonConvenor ||
			isSeasonManager ||
			isSeasonObserver ||
			isDivisionAdmin ||
			isDivisionDirector ||
			isDivisionConvenor ||
			isDivisionManager ||
			isDivisionObserver ||
			isTeamAdmin ||
			isTeamDirector ||
			isTeamConvenor ||
			isTeamManager ||
			isTeamObserver
		) {
			rules.push(
				...[
					{
						subject: "rosterMemberActions",
						actions: ["read"],
						conditions: { teamId: id },
					},
				]
			);
		}

		if (isTeamAdmin || isTeamDirector || isTeamConvenor || isTeamScheduler) {
			rules.push(
				...[
					{
						subject: "scheduledGames",
						actions: ["create"],
						conditions: { seasonId: season.id },
					},
					{
						subject: "brackets",
						actions: ["create"],
						conditions: { seasonId: season.id },
					},
				]
			);
		}

		return rules;
	}, []);
}

function buildPlayersRules(roles, players) {
	return players.reduce((rules, player) => {
		let { id, association, league, season, teams, divisions } = player;

		association = association || { id: 0 };
		league = league || { id: 0 };
		season = season || { id: 0 };
		let divisionIds = (divisions || []).map(({ id }) => id);
		let teamIds = (teams || []).map(({ id }) => id);

		const isAssociationAdmin = getIsAssociationAdmin(roles, association.id);
		const isAssociationDirector = getIsAssociationDirector(
			roles,
			association.id
		);
		const isAssociationConvenor = getIsAssociationConvenor(
			roles,
			association.id
		);
		const isAssociationManager = getIsAssociationManager(roles, association.id);
		const isAssociationObserver = getIsAssociationObserver(
			roles,
			association.id
		);
		const isAssociationGuest = getIsAssociationGuest(roles, association.id);
		const isLeagueAdmin = getIsLeagueAdmin(roles, league.id);
		const isLeagueDirector = getIsLeagueDirector(roles, league.id);
		const isLeagueConvenor = getIsLeagueConvenor(roles, league.id);
		const isLeagueManager = getIsLeagueManager(roles, league.id);
		const isLeagueObserver = getIsLeagueObserver(roles, league.id);
		const isLeagueGuest = getIsLeagueGuest(roles, league.id);
		const isSeasonAdmin = getIsSeasonAdmin(roles, season.id);
		const isSeasonDirector = getIsSeasonDirector(roles, season.id);
		const isSeasonConvenor = getIsSeasonConvenor(roles, season.id);
		const isSeasonManager = getIsSeasonManager(roles, season.id);
		const isSeasonObserver = getIsSeasonObserver(roles, season.id);
		const isSeasonGuest = getIsSeasonGuest(roles, season.id);
		const isDivisionAdmin = getIsDivisionAdmin(roles, ...divisionIds);
		const isDivisionDirector = getIsDivisionDirector(roles, ...divisionIds);
		const isDivisionConvenor = getIsDivisionConvenor(roles, ...divisionIds);
		const isDivisionManager = getIsDivisionManager(roles, ...divisionIds);
		const isDivisionObserver = getIsDivisionObserver(roles, ...divisionIds);
		const isDivisionGuest = getIsDivisionGuest(roles, ...divisionIds);
		const isTeamAdmin = getIsTeamAdmin(roles, ...teamIds);
		const isTeamDirector = getIsTeamDirector(roles, ...teamIds);
		const isTeamConvenor = getIsTeamConvenor(roles, ...teamIds);
		const isTeamManager = getIsTeamManager(roles, ...teamIds);
		const isTeamObserver = getIsTeamObserver(roles, ...teamIds);
		const isTeamGuest = getIsTeamGuest(roles, ...teamIds);

		if (
			isAssociationAdmin ||
			isAssociationDirector ||
			isAssociationConvenor ||
			isAssociationManager ||
			isAssociationObserver ||
			isAssociationGuest ||
			isLeagueAdmin ||
			isLeagueDirector ||
			isLeagueConvenor ||
			isLeagueManager ||
			isLeagueObserver ||
			isLeagueGuest ||
			isSeasonAdmin ||
			isSeasonDirector ||
			isSeasonConvenor ||
			isSeasonManager ||
			isSeasonObserver ||
			isSeasonGuest ||
			isDivisionAdmin ||
			isDivisionDirector ||
			isDivisionConvenor ||
			isDivisionManager ||
			isDivisionObserver ||
			isDivisionGuest ||
			isTeamAdmin ||
			isTeamDirector ||
			isTeamConvenor ||
			isTeamManager ||
			isTeamObserver ||
			isTeamGuest
		) {
			rules.push(
				...[
					{
						subject: "players",
						actions: ["read"],
						conditions: { id },
					},
				]
			);
		}

		if (
			isAssociationAdmin ||
			isAssociationDirector ||
			isAssociationConvenor ||
			isAssociationManager ||
			isLeagueAdmin ||
			isLeagueDirector ||
			isLeagueConvenor ||
			isLeagueManager ||
			isSeasonAdmin ||
			isSeasonDirector ||
			isSeasonConvenor ||
			isSeasonManager ||
			isDivisionAdmin ||
			isDivisionDirector ||
			isDivisionConvenor ||
			isDivisionManager ||
			isTeamAdmin ||
			isTeamDirector ||
			isTeamConvenor ||
			isTeamManager
		) {
			rules.push(
				...[
					{
						subject: "players",
						actions: ["update", "addToTeam"],
						conditions: { id },
					},
				]
			);
		}

		if (
			isAssociationAdmin ||
			isAssociationDirector ||
			isAssociationConvenor ||
			isLeagueAdmin ||
			isLeagueDirector ||
			isLeagueConvenor ||
			isSeasonAdmin ||
			isSeasonDirector ||
			isSeasonConvenor ||
			isDivisionAdmin ||
			isDivisionDirector ||
			isDivisionConvenor ||
			isTeamAdmin ||
			isTeamDirector ||
			isTeamConvenor
		) {
			rules.push(
				...[
					{
						subject: "players",
						actions: ["delete"],
						conditions: { id },
					},
				]
			);
		}

		if (
			isAssociationAdmin ||
			isAssociationDirector ||
			isAssociationConvenor ||
			isAssociationObserver ||
			isLeagueAdmin ||
			isLeagueDirector ||
			isLeagueConvenor ||
			isLeagueObserver ||
			isSeasonAdmin ||
			isSeasonDirector ||
			isSeasonConvenor ||
			isSeasonObserver ||
			isDivisionAdmin ||
			isDivisionDirector ||
			isDivisionConvenor ||
			isDivisionObserver ||
			isTeamAdmin ||
			isTeamDirector ||
			isTeamConvenor ||
			isTeamObserver
		) {
			rules.push(
				...[
					{
						subject: "penaltyReports",
						actions: ["read"],
						conditions: { playerId: id },
					},
				]
			);
		}

		return rules;
	}, []);
}

function buildCoachesRules(roles, coaches) {
	return coaches.reduce((rules, coach) => {
		let { id, association, league, season, teams, divisions } = coach;

		association = association || { id: 0 };
		league = league || { id: 0 };
		season = season || { id: 0 };
		let divisionIds = (divisions || []).map(({ id }) => id);
		let teamIds = (teams || []).map(({ id }) => id);

		const isAssociationAdmin = getIsAssociationAdmin(roles, association.id);
		const isAssociationDirector = getIsAssociationDirector(
			roles,
			association.id
		);
		const isAssociationConvenor = getIsAssociationConvenor(
			roles,
			association.id
		);
		const isAssociationManager = getIsAssociationManager(roles, association.id);
		const isAssociationObserver = getIsAssociationObserver(
			roles,
			association.id
		);
		const isAssociationGuest = getIsAssociationGuest(roles, association.id);
		const isLeagueAdmin = getIsLeagueAdmin(roles, league.id);
		const isLeagueDirector = getIsLeagueDirector(roles, league.id);
		const isLeagueConvenor = getIsLeagueConvenor(roles, league.id);
		const isLeagueManager = getIsLeagueManager(roles, league.id);
		const isLeagueObserver = getIsLeagueObserver(roles, league.id);
		const isLeagueGuest = getIsLeagueGuest(roles, league.id);
		const isSeasonAdmin = getIsSeasonAdmin(roles, season.id);
		const isSeasonDirector = getIsSeasonDirector(roles, season.id);
		const isSeasonConvenor = getIsSeasonConvenor(roles, season.id);
		const isSeasonManager = getIsSeasonManager(roles, season.id);
		const isSeasonObserver = getIsSeasonObserver(roles, season.id);
		const isSeasonGuest = getIsSeasonGuest(roles, season.id);
		const isDivisionAdmin = getIsDivisionAdmin(roles, ...divisionIds);
		const isDivisionDirector = getIsDivisionDirector(roles, ...divisionIds);
		const isDivisionConvenor = getIsDivisionConvenor(roles, ...divisionIds);
		const isDivisionManager = getIsDivisionManager(roles, ...divisionIds);
		const isDivisionObserver = getIsDivisionObserver(roles, ...divisionIds);
		const isDivisionGuest = getIsDivisionGuest(roles, ...divisionIds);
		const isTeamAdmin = getIsTeamAdmin(roles, ...teamIds);
		const isTeamDirector = getIsTeamDirector(roles, ...teamIds);
		const isTeamConvenor = getIsTeamConvenor(roles, ...teamIds);
		const isTeamManager = getIsTeamManager(roles, ...teamIds);
		const isTeamObserver = getIsTeamObserver(roles, ...teamIds);
		const isTeamGuest = getIsTeamGuest(roles, ...teamIds);

		if (
			isAssociationAdmin ||
			isAssociationDirector ||
			isAssociationConvenor ||
			isAssociationManager ||
			isAssociationObserver ||
			isAssociationGuest ||
			isLeagueAdmin ||
			isLeagueDirector ||
			isLeagueConvenor ||
			isLeagueManager ||
			isLeagueObserver ||
			isLeagueGuest ||
			isSeasonAdmin ||
			isSeasonDirector ||
			isSeasonConvenor ||
			isSeasonManager ||
			isSeasonObserver ||
			isSeasonGuest ||
			isDivisionAdmin ||
			isDivisionDirector ||
			isDivisionConvenor ||
			isDivisionManager ||
			isDivisionObserver ||
			isDivisionGuest ||
			isTeamAdmin ||
			isTeamDirector ||
			isTeamConvenor ||
			isTeamManager ||
			isTeamObserver ||
			isTeamGuest
		) {
			rules.push(
				...[
					{
						subject: "coaches",
						actions: ["read"],
						conditions: { id },
					},
				]
			);
		}

		if (
			isAssociationAdmin ||
			isAssociationDirector ||
			isAssociationConvenor ||
			isAssociationManager ||
			isLeagueAdmin ||
			isLeagueDirector ||
			isLeagueConvenor ||
			isLeagueManager ||
			isSeasonAdmin ||
			isSeasonDirector ||
			isSeasonConvenor ||
			isSeasonManager ||
			isDivisionAdmin ||
			isDivisionDirector ||
			isDivisionConvenor ||
			isDivisionManager ||
			isTeamAdmin ||
			isTeamDirector ||
			isTeamConvenor ||
			isTeamManager
		) {
			rules.push(
				...[
					{
						subject: "coaches",
						actions: ["update", "addToTeam"],
						conditions: { id },
					},
				]
			);
		}

		if (
			isAssociationAdmin ||
			isAssociationDirector ||
			isAssociationConvenor ||
			isLeagueAdmin ||
			isLeagueDirector ||
			isLeagueConvenor ||
			isSeasonAdmin ||
			isSeasonDirector ||
			isSeasonConvenor ||
			isDivisionAdmin ||
			isDivisionDirector ||
			isDivisionConvenor ||
			isTeamAdmin ||
			isTeamDirector ||
			isTeamConvenor
		) {
			rules.push(
				...[
					{
						subject: "coaches",
						actions: ["delete"],
						conditions: { id },
					},
				]
			);
		}

		if (
			isAssociationAdmin ||
			isAssociationDirector ||
			isAssociationConvenor ||
			isAssociationObserver ||
			isLeagueAdmin ||
			isLeagueDirector ||
			isLeagueConvenor ||
			isLeagueObserver ||
			isSeasonAdmin ||
			isSeasonDirector ||
			isSeasonConvenor ||
			isSeasonObserver ||
			isDivisionAdmin ||
			isDivisionDirector ||
			isDivisionConvenor ||
			isDivisionObserver ||
			isTeamAdmin ||
			isTeamDirector ||
			isTeamConvenor ||
			isTeamObserver
		) {
			rules.push(
				...[
					{
						subject: "penaltyReports",
						actions: ["read"],
						conditions: { coachId: id },
					},
				]
			);
		}

		return rules;
	}, []);
}

function buildRefereesRules(roles, referees) {
	return referees.reduce((rules, referee) => {
		let { id, association, league, season } = referee;

		association = association || { id: 0 };
		league = league || { id: 0 };
		season = season || { id: 0 };

		const isAssociationAdmin = getIsAssociationAdmin(roles, association.id);
		const isAssociationDirector = getIsAssociationDirector(
			roles,
			association.id
		);
		const isAssociationConvenor = getIsAssociationConvenor(
			roles,
			association.id
		);
		const isAssociationObserver = getIsAssociationObserver(
			roles,
			association.id
		);
		const isLeagueAdmin = getIsLeagueAdmin(roles, league.id);
		const isLeagueDirector = getIsLeagueDirector(roles, league.id);
		const isLeagueConvenor = getIsLeagueConvenor(roles, league.id);
		const isLeagueObserver = getIsLeagueObserver(roles, league.id);
		const isSeasonAdmin = getIsSeasonAdmin(roles, season.id);
		const isSeasonDirector = getIsSeasonDirector(roles, season.id);
		const isSeasonConvenor = getIsSeasonConvenor(roles, season.id);
		const isSeasonObserver = getIsSeasonObserver(roles, season.id);

		if (
			isAssociationAdmin ||
			isAssociationDirector ||
			isAssociationConvenor ||
			isLeagueAdmin ||
			isLeagueDirector ||
			isLeagueConvenor ||
			isSeasonAdmin ||
			isSeasonDirector ||
			isSeasonConvenor
		) {
			rules.push(
				...[
					{
						subject: "referees",
						actions: ["update", "delete"],
						conditions: { id },
					},
				]
			);
		}

		if (
			isAssociationAdmin ||
			isAssociationDirector ||
			isAssociationConvenor ||
			isAssociationObserver ||
			isLeagueAdmin ||
			isLeagueDirector ||
			isLeagueConvenor ||
			isLeagueObserver ||
			isSeasonAdmin ||
			isSeasonDirector ||
			isSeasonConvenor ||
			isSeasonObserver
		) {
			rules.push(
				...[
					{
						subject: "refereeReports",
						actions: ["read"],
						conditions: { refereeId: id },
					},
				]
			);
		}

		return rules;
	}, []);
}

export default function buildAbilityRules({ roles, resources }) {
	return Object.entries(resources).reduce(
		(rules, [resourceType, resourceList]) => {
			switch (resourceType) {
				case "associations":
					rules.push(...buildAssociationRules(roles, resourceList));
					break;
				case "leagues":
					rules.push(...buildLeaguesRules(roles, resourceList));
					break;
				case "seasons":
					rules.push(...buildSeasonsRules(roles, resourceList));
					break;
				case "archivedSeasons":
					rules.push(...buildArchivedSeasonsRules(roles, resourceList));
					break;
				case "personPenaltyReportSeasons":
					rules.push(...buildSeasonsRules(roles, resourceList));
					break;
				case "games":
					rules.push(...buildGamesRules(roles, resourceList));
					break;
				case "scheduledGames":
					rules.push(...buildScheduledGamesRules(roles, resourceList));
					break;
				case "brackets":
					rules.push(...buildBracketRules(roles, resourceList));
					break;
				case "personPenaltyReportGames":
					rules.push(...buildGamesRules(roles, resourceList));
					break;
				case "suspensionReports":
					rules.push(...buildSuspensionReportsRules(roles, resourceList));
					break;
				case "divisions":
					rules.push(...buildDivisionsRules(roles, resourceList));
					break;
				case "teams":
					rules.push(...buildTeamsRules(roles, resourceList));
					break;
				case "teamBillingReports":
					rules.push(...buildTeamsRules(roles, resourceList));
					break;
				case "suspensionReportTeams":
					rules.push(...buildTeamsRules(roles, resourceList));
					break;
				case "players":
					rules.push(...buildPlayersRules(roles, resourceList));
					break;
				case "coaches":
					rules.push(...buildCoachesRules(roles, resourceList));
					break;
				case "referees":
					rules.push(...buildRefereesRules(roles, resourceList));
					break;
				default:
					void 0;
			}

			return rules;
		},
		[]
	);
}
