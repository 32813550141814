import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {
	ButtonDropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	Button,
} from "reactstrap";
import { Can, canCheck } from "@/components/Ability";

function ActionsDropdown({
	bracketId,
	isDeleting,
	disabled,
	onEdit,
	onViewStats,
	onDelete,
	seasonId,
}) {
	const [isOpen, setIsOpen] = React.useState(false);

	const toggleIsOpened = () => setIsOpen((prev) => !prev);

	return (
		<>
			{isDeleting ? (
				<Button color="danger" size="sm" outline disabled>
					Deleting…
				</Button>
			) : (
				<ButtonDropdown isOpen={isOpen} toggle={toggleIsOpened}>
					<DropdownToggle
						caret
						size="sm"
						color="secondary"
						outline
						disabled={disabled}
					>
						Actions
					</DropdownToggle>
					<DropdownMenu right>
						{/* TODO: Add update permission check needs to be added to resources specifically for brackets */}
						<Can I="create" a="scheduledGames" of={{ seasonId }}>
							<DropdownItem onClick={() => onEdit(bracketId)}>
								Edit
							</DropdownItem>
						</Can>
						<DropdownItem onClick={() => onViewStats(bracketId)}>
							View in Stats Widget
						</DropdownItem>

						{/* TODO: Add delete permission check needs to be added to resources specifically for brackets */}
						<Can I="create" a="scheduledGames" of={{ seasonId }}>
							<DropdownItem divider />
							<DropdownItem
								className="text-danger"
								onClick={() => onDelete(bracketId)}
							>
								Delete
							</DropdownItem>
						</Can>
					</DropdownMenu>
				</ButtonDropdown>
			)}
		</>
	);
}

ActionsDropdown.propTypes = {
	bracketId: PropTypes.string.isRequired,
	isDeleting: PropTypes.bool.isRequired,
	disabled: PropTypes.bool.isRequired,
	onEdit: PropTypes.func.isRequired,
	onViewStats: PropTypes.func.isRequired,
	onDelete: PropTypes.func.isRequired,
	seasonId: PropTypes.string.isRequired,
};

export default ActionsDropdown;
