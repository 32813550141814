import PageLoader from "@/components/PageLoader";
import React from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import ActionsDropdown from "./components/ActionsDropdown";
import EmptyListMessage from "@/components/EmptyListMessage";
import getBracketNameRenderer from "./components/BracketNameRenderer";
import { useOTP } from "@/lib/core/useOTP";

// Running todo list
// 1. Fix stats widget url after we build out the new page in that application. Currently will open standings page.
// 3. Handle back from scheuled game page after saving works but we may need to pass along some more info to the new app.
// 4. On Delete network request details needs to be done.
// 5. + New bracket url and info we need to pass along to the new page. currently opens gamesheetinc.com

export default function BracketList({
	season,
	bracketList,
	loading,
	isLoaded,
	onEdit,
	onViewStats,
	onDeleteBracket,
}) {
	const onGridReady = (params) => {
		params.columnApi.applyColumnState({
			state: [{ colId: "updatedAt", sort: "desc" }],
		});
	};

	const dateFormatter = (params) => {
		if (!params.value) return "";
		const date = new Date(params.value);
		return date.toLocaleDateString("en-US", {
			month: "short",
			day: "numeric",
			year: "numeric",
		});
	};

	const { otp } = useOTP();

	return (
		<PageLoader isLoaded={isLoaded} isLoading={loading}>
			{bracketList && bracketList.length > 0 ? (
				<div className="brackets-index">
					<div className="ag-theme-alpine ag-gs-theme">
						<AgGridReact
							defaultColDef={{
								resizable: true,
								wrapText: true,
								autoHeight: true,
							}}
							pagination={false}
							rowData={bracketList}
							onGridReady={onGridReady}
							domLayout={"autoHeight"}
							suppressCellSelection={true}
							rowHeight={44}
							enableCellTextSelection={true}
							getRowNodeId={(data) => data.id}
							frameworkComponents={{
								bracketNameRenderer: getBracketNameRenderer(season.id, otp),
							}}
						>
							<AgGridColumn
								headerName="Title"
								field="title"
								minWidth="200"
								flex="2"
								sortable={true}
								sortingOrder={["asc", "desc"]}
								unSortIcon={true}
								cellRenderer="bracketNameRenderer"
							/>
							<AgGridColumn
								headerName="Games"
								field="games"
								minWidth="100"
								flex="1"
								sortable={true}
								sortingOrder={["desc", "asc"]}
								unSortIcon={true}
								cellStyle={{ textAlign: "center" }}
							/>
							<AgGridColumn
								headerName="Created"
								field="createdAt"
								minWidth="150"
								flex="1"
								sortable={true}
								valueFormatter={dateFormatter}
								sortingOrder={["desc", "asc"]}
								unSortIcon={true}
							/>
							<AgGridColumn
								headerName="Last Modified Date"
								field="updatedAt"
								minWidth="150"
								flex="1"
								sortable={true}
								valueFormatter={dateFormatter}
								sortingOrder={["desc", "asc"]}
								unSortIcon={true}
							/>
							<AgGridColumn
								headerName="Actions"
								field="id"
								minWidth="120"
								flex="1"
								sortable={false}
								cellRendererFramework={(params) => {
									const { id, title } = params.data;
									return (
										<ActionsDropdown
											bracketId={id}
											title={title}
											onEdit={onEdit}
											onViewStats={onViewStats}
											onDelete={() => onDeleteBracket(params.data)}
											isDeleting={false}
											disabled={false}
											seasonId={season.id}
										/>
									);
								}}
							/>
						</AgGridReact>
					</div>
				</div>
			) : (
				<EmptyListMessage>
					<p>No brackets available for this season.</p>
				</EmptyListMessage>
			)}
		</PageLoader>
	);
}
