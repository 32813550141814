import { handleActions } from "redux-actions";

import {
	loadCoachPositionsRoutine,
	loadingRoutine,
	loadPlayerPositionsRoutine,
	loadSportSettingsRoutine,
} from "../routines";
import actions from "../actions";
import {
	parsePenaltySettings,
	parsePenaltyDurationsFromLengths,
} from "@/utils/standardizePenaltySettings";

const initialState = {
	id: "",
	title: "",
	externalId: "",
	association: { id: "", title: "" },
	league: { id: "", title: "" },
	startDate: null,
	endDate: null,
	statsYear: "",
	generalSettings: {
		default_player_status: "playing",
		periodLengths: {},
		gameCategories: [],
		signatures_required: "",
		shootout: false,
		manager_scheduling: false,
		restrict_locked_roster_additions: false,
	},
	statsSettings: {
		assistValue: 0,
		goalValue: 0,
		playerStatsDisplay: "",
	},
	penaltySettings: {
		penaltyCodes: [],
		penaltyDurations: [],
		penaltyClasses: [],
		penaltyLengths: [],
	},
	flaggingSettings: {
		criteria: {},
		penalties: [],
	},
	playerOfTheGame: null,
	liveScoringMode: "public",
	sport: "",
	sportsSettings: {
		maxJerseyNumberLength: 2,
	},
	playerPositions: [
		{
			key: "centre",
			value: "Centre",
		},
		{
			key: "right_wing",
			value: "Right Wing",
		},
		{
			key: "left_wing",
			value: "Left Wing",
		},
		{
			key: "defence",
			value: "Defence",
		},
		{
			key: "forward",
			value: "Forward",
		},
		{
			key: "goalie",
			value: "Goalie",
		},
		{
			key: "transition",
			value: "Transition",
		},
	],
	coachPositions: [
		{
			key: "head_coach",
			value: "Head Coach",
		},
		{
			key: "assistant_coach",
			value: "Assistant Coach",
		},
		{
			key: "trainer",
			value: "Trainer",
		},
		{
			key: "assistant_trainer",
			value: "Assistant Trainer",
		},
		{
			key: "manager",
			value: "Manager",
		},
		{
			key: "head_coach_at_large",
			value: "Head Coach At Large",
		},
		{
			key: "assistant_coach_at_large",
			value: "Assistant Coach At Large",
		},
		{
			key: "trainer_at_large",
			value: "Trainer At Large",
		},
	],
};

function reduceLoadingSuccess(state, { payload: { season } }) {
	const {
		id,
		title,
		externalId,
		association,
		league,
		settings,
		flaggedPenalties,
		flaggingCriteria,
		playerOfTheGame,
		startDate,
		endDate,
		liveScoringMode,
		sport,
		statsYear,
	} = season;

	let { penaltyCodes, penaltyDurations } = parsePenaltySettings(settings);
	let penaltyClasses = settings.penaltyClasses || [];
	let penaltyLengths = settings.penaltyLengths || [];
	// fallback to penaltyLengths if we have lengths but not durations
	if (penaltyDurations.length <= 0 && penaltyLengths.length > 0) {
		penaltyDurations = parsePenaltyDurationsFromLengths(settings);
	}

	return {
		id,
		title,
		externalId,
		startDate,
		endDate,
		statsYear,
		association: { id: association.id, title: association.title },
		league: { id: league.id, title: league.title },
		generalSettings: {
			periodLengths: settings.periodLengths,
			gameCategories: settings.gameCategories || [],
			signatures_required: settings.signaturesRequired || "head_coach",
			default_player_status: settings.defaultPlayerStatus || "playing",
			shootout: settings.shootout || false,
			manager_scheduling: settings.managerScheduling || false,
			restrict_locked_roster_additions:
				settings.restrictLockedRosterAdditions || false,
		},
		statsSettings: {
			assistValue: settings.assistValue,
			goalValue: settings.goalValue,
			playerStatsDisplay: settings.playerStatsDisplay,
			pointSystem: settings.pointSystem,
			fairPlayPoints:
				"fairPlayPoints" in settings
					? settings.fairPlayPoints
					: {
							isEnabled: false,
							maxPenaltyMinutes: 16,
							pointValue: 1,
					  },
			tieBreakers: settings.tieBreakers,
			maxGoalDifferential: settings.maxGoalDifferential,
		},
		penaltySettings: {
			penaltyCodes,
			penaltyDurations,
			penaltyClasses,
			penaltyLengths,
		},
		flaggingSettings: {
			criteria: flaggingCriteria || {},
			penalties: flaggedPenalties || [],
		},
		playerOfTheGame,
		liveScoringMode,
		leagueAppMode: settings.leagueAppMode || "disabled",
		sport,
		playerPositions: [],
		coachPositions: [],
	};
}

function reduceSportSettingsLoadingSuccess(state, { payload: { settings } }) {
	return {
		...state,
		sportsSettings: settings,
	};
}

function reducePlayerPositionLoadingSuccess(state, { payload: { values } }) {
	return {
		...state,
		playerPositions: values,
	};
}

function reduceCoachPositionLoadingSuccess(state, { payload: { values } }) {
	return {
		...state,
		coachPositions: values,
	};
}

export default handleActions(
	{
		[loadingRoutine.SUCCESS]: reduceLoadingSuccess,
		[loadSportSettingsRoutine.SUCCESS]: reduceSportSettingsLoadingSuccess,
		[loadPlayerPositionsRoutine.SUCCESS]: reducePlayerPositionLoadingSuccess,
		[loadCoachPositionsRoutine.SUCCESS]: reduceCoachPositionLoadingSuccess,
		[actions.clear]: () => ({ ...initialState }),
	},
	initialState
);
