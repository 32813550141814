import { config } from "@/config";
import { useState, useEffect } from "react";
import { firebase } from "@/firebase";

const useBracketList = ({ seasonId, otp }) => {
	const [bracketList, setBracketList] = useState([]);
	const [loading, setLoading] = useState(true);
	const [isLoaded, setIsLoaded] = useState(false);

	useEffect(() => {
		let isMounted = true;

		setLoading(true);
		setIsLoaded(false);

		const fetchBracketList = async () => {
			if (!seasonId) {
				return;
			}
			try {
				const bracketsCollection = firebase
					.firestore()
					.collection(`seasons/${seasonId}/brackets`);
				const unsubscribe = bracketsCollection.onSnapshot(
					(snapshot) => {
						if (isMounted) {
							const brackets = snapshot.docs.map((doc) => {
								const data = doc.data();
								const gamesCount = Object.values(data.games || {}).filter(
									(game) => game !== null
								).length;

								return {
									id: doc.id,
									title: data.title || "Untitled Bracket",
									games: gamesCount,
									createdAt: data.meta.createdAt.seconds
										? new Date(data.meta.createdAt.seconds * 1000).toISOString()
										: null,
									updatedAt: data.meta.updatedAt.seconds
										? new Date(data.meta.updatedAt.seconds * 1000).toISOString()
										: null,
								};
							});

							if (!brackets.length) {
								setBracketList([]);
								setIsLoaded(false);
							}
							setBracketList(brackets);
							setIsLoaded(true);
						}
					},
					(error) => {
						console.error("Error fetching bracket list:", error);
						if (isMounted) {
							setLoading(false);
						}
					}
				);

				return () => {
					unsubscribe();
				};
			} catch (error) {
				console.error("Error fetching bracket list:", error);
			} finally {
				setLoading(false);
			}
		};

		fetchBracketList();

		return () => {
			isMounted = false;
		};
	}, [seasonId]);

	const onEdit = (id) => {
		const bracketWindow = window.open(
			`${config.V5_ADMIN_DASHBOARD_URL}/seasons/${seasonId}/games/brackets/${id}?otp=${otp}`,
			"gamesheet-brackets"
		);
		if (bracketWindow) {
			bracketWindow.focus();
		}
	};

	const onViewStats = (id) => {
		const url = `${config.STATS_WIDGET}/seasons/${seasonId}/brackets?filter[bracketIds]=${id}`;
		window.open(url, "gamesheet-statswidget");
	};

	const onDelete = async (id) => {
		setLoading(true);

		try {
			// Wait for auth state to be ready replaces firebase.auth().currentUser
			const currentUser = await new Promise((resolve) => {
				const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
					// Stop listening after first response
					unsubscribe();
					resolve(user);
				});
			});

			if (!currentUser) {
				throw new Error("User not authenticated");
			}

			const bracketDoc = firebase
				.firestore()
				.doc(`seasons/${seasonId}/brackets/${id}`);
			await bracketDoc.delete();
		} catch (error) {
			setLoading(false);
			console.error("Error deleting bracket:", error);
			console.error("Error details:", {
				code: error.code,
				message: error.message,
				stack: error.stack,
			});
		}

		setLoading(false);
	};

	return { bracketList, loading, isLoaded, onEdit, onViewStats, onDelete };
};

export default useBracketList;
